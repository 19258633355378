import CardLayout from '@ui/core/CardLayout'
import { TextField } from '@ui/core/TextField'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { IconButton, type SxProps, useTheme } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import Tooltip from '@mui/material/Tooltip'
import { type ChangeEvent, type FormEvent, type ReactElement, type ReactNode, useState } from 'react'
import { Conversation } from '../conversation/Conversation'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props {
	onNewMessage: (message: string) => void
	loading: boolean
	children: ReactNode
	sx?: SxProps
}

export const ConversationFrame = ({ onNewMessage, loading, children, sx }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const theme = useTheme()
	const [typedMessage, setTypedMessage] = useState('')

	const handleSubmit = (e: FormEvent): void => {
		e.preventDefault()

		if (typedMessage.length < 1) return
		if (onNewMessage != null) onNewMessage(typedMessage)

		setTypedMessage('')
	}

	return (
		<CardLayout
			sx={{
				height: '75vh',
				display: 'flex',
				flexDirection: 'column',
				...sx
			}}
		>
			<Conversation
				style={{
					paddingLeft: 15,
					paddingRight: 15,
					paddingTop: 15,
					flex: 1,
					overflow: 'auto'
				}}
			>
				{children}
			</Conversation>
			<form
				style={{
					background: theme.palette.darkBlue.light,
					color: 'white',
					padding: '15px 15px',

					display: 'flex',
					alignItems: 'center',
					flexShrink: 0
				}}
				onSubmit={handleSubmit}
			>
				<TextField
					size="small"
					disabled={loading}
					style={{
						background: '#fff',
						borderRadius: 20,
						flex: 1,
						marginRight: 25
					}}
					fullWidth
					placeholder={t('translation:conversationFrame.sendMessage')}
					value={typedMessage}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setTypedMessage(e.target.value)
					}}
				/>

				<Tooltip title={t('translation:conversationFrame.sendMessageTooltip')}>
					<IconButton type="submit" disabled={loading} color="inherit">
						<LocalLoader load={loading} white size={24}>
							<SendIcon />
						</LocalLoader>
					</IconButton>
				</Tooltip>
			</form>
		</CardLayout>
	)
}
