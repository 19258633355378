import { type FC } from 'react'
import InputTextField from '@ui/form/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Control, type FieldValues } from 'react-hook-form'
import { type TranslatedString } from '@gqlTypes'

interface Props {
	control: Control<FieldValues>
	defaultValue?: TranslatedString
	fieldName: string
	translationKey: string
}

export const TranslatedMessageField: FC<Props> = ({ control, defaultValue, fieldName, translationKey }) => {
	const { t } = useCustomTranslation()
	const interpolation = {
		prefix: '{{',
		suffix: '}}'
	}
	return (
		<>
			<TableLayoutRow
				title={t(`${translationKey}.fr.title`)}
				tooltip={t(`${translationKey}.fr.tooltip`, { interpolation })}
			>
				<InputTextField control={control} name={`${fieldName}.fr`} defaultValue={defaultValue?.fr ?? ''} multiline />
			</TableLayoutRow>
			<TableLayoutRow
				title={t(`${translationKey}.en.title`)}
				tooltip={t(`${translationKey}.en.tooltip`, { interpolation })}
			>
				<InputTextField control={control} name={`${fieldName}.en`} defaultValue={defaultValue?.en ?? ''} multiline />
			</TableLayoutRow>
		</>
	)
}
