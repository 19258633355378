import { Stack, type SxProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import EastIcon from '@mui/icons-material/East'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	tendency: number
	previousCompletion?: number
	completion: number
}

export const ObjectiveTendency = ({ tendency, previousCompletion, completion }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const sx: SxProps = { fontSize: 45 }

	let icon = <EastIcon color="primary" sx={sx} />
	const isPositive = tendency > 0
	const prefix = isPositive ? '+' : ''
	if (isPositive) icon = <NorthEastIcon color="secondary" sx={sx} />
	if (tendency < 0) icon = <SouthEastIcon color="warning" sx={sx} />
	if (tendency === 0) icon = <EastIcon color="info" sx={sx} />

	return (
		<>
			{previousCompletion !== 0 ? (
				<Stack direction="row" alignItems={'center'} justifyContent={'center'} gap={1}>
					{icon}
					<Stack>
						<Typography>{`${prefix}${tendency}%`}</Typography>
						<Typography>{t('interview:objectives.list.tendency.label')}</Typography>
					</Stack>
				</Stack>
			) : (
				<>-</>
			)}
		</>
	)
}
