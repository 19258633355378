import { MassMessages } from '../../../features/modals/pages/MassMessages'
import { MassReminders } from '../../../features/modals/pages/MassReminders'
import { CheckboxInput } from '../../../features/lists/components/Filters/CheckboxInput'
import { Fullname } from '../../../features/lists/components/Filters/Fullname'
import { MassFeedback } from '@domains/brandFeedbacks/massFeedback/MassFeedback'
import { TrackPositionFilter } from '../../../features/lists/components/Filters/TrackPositionFilter'
import BusinessUnitFilter from '../../../features/lists/components/Filters/BusinessUnitFilter'
import ManagersFilter from '../../../features/lists/components/Filters/ManagersFilter'
import {
	type GetCommunityMembersListQuery,
	type GetCommunityMembersListQueryVariables,
	IntegrationCategory,
	TrackType,
	useMeQuery,
	useUpdateManyUserIntegrationsMutation
} from '@gqlTypes'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { type ApolloQueryResult } from '@apollo/client'
import { ExportGQL } from '@features/modals/components/actions/ExportGQL'
import { USER_POSITION_COMUNITY_EXPORT } from './ExportCommunityMembers'
import { Divider } from '@mui/material'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useRole } from '@shared/hooks/useRole'

interface Props {
	isAllRowsSelected: boolean
	refetch: (
		variables?: Partial<GetCommunityMembersListQueryVariables>
	) => Promise<ApolloQueryResult<GetCommunityMembersListQuery>>
	dataList: NonNullable<GetCommunityMembersListQuery['getCommunityMembersList']>['nodes']
	count: number
}

export const CommunityMembersFilterBar = ({ isAllRowsSelected, refetch, dataList, count }: Props): ReactElement => {
	const [updateManyUserIntegrations, { loading: mutationLoading }] = useUpdateManyUserIntegrationsMutation()
	const { data: me, loading: meLoading } = useMeQuery()
	const { t } = useCustomTranslation()
	const { screens } = useRole()
	const isLoading = mutationLoading || meLoading
	const hasOrgaSkills = (me?.me?.organization.orgaSkills?.length ?? 0) > 0
	const isRestrictedAccess = me?.me?.isRestrictedAccess ?? false
	const massAction = {
		trackType: TrackType.Position,
		integrationCategory: IntegrationCategory.Community,
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<Filters refetch={refetch}>
				<FiltersContent>
					<Fullname />
					<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
				</FiltersContent>
				<Divider />
				<FiltersContent>
					<TrackPositionFilter keyName="orgaPosition.id" keyLabel="track" />
					<BusinessUnitFilter />
					{!isRestrictedAccess && <ManagersFilter keyLabel="referents" />}
					<CheckboxInput
						keyName="steps.brandFeedbackRequest"
						translationKey="lists:filter.steps.brandFeedbackRequest"
						defaultValue={undefined}
						isReversedValue={undefined}
					/>
					<CheckboxInput
						keyName="steps.brandFeedbackResponse"
						translationKey="lists:filter.steps.brandFeedbackResponse"
						defaultValue={undefined}
						isReversedValue={undefined}
					/>
					<CheckboxInput
						keyName="isArchived"
						translationKey="lists:filter.archived"
						defaultValue={undefined}
						isReversedValue={undefined}
					/>
				</FiltersContent>
			</Filters>
			{screens.communityMembers.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={isLoading}
					count={count}
				/>
			) : null}
			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL
				isAllRowsSelected={isAllRowsSelected}
				dataList={dataList}
				QUERY={USER_POSITION_COMUNITY_EXPORT}
				isNotList={undefined}
			/>
		</LeftBar>
	)
}
