import type React from 'react'
import { useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { DataGridGQL } from '../../../features/lists/components/DataGridGQL'
import {
	type UpdateUserPositionInput,
	useGetCommunityMembersListQuery,
	useUpdateUserPositionMutation,
	ScreenId
} from '@gqlTypes'
import { CommunityMembersFilterBar } from './CommunityMembersFilterBar'
import { useCommunityMembersColumns } from './useCommunityMembersColumns'
import { useFilters } from '@shared/hooks/useFilters'
import PeopleIcon from '@mui/icons-material/People'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { useOrganization } from '@domains/organization/useOrganization'

export const CommunityMembers: React.FC = () => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('communityMembers:title'))
	useCheckRoleSecurity(ScreenId.CommunityMembers)
	const { filters } = useFilters()

	const [updateUserPosition] = useUpdateUserPositionMutation()
	const query = async (userPositionId: string, userPositionInput: UpdateUserPositionInput): Promise<void> => {
		await updateUserPosition({ variables: { userPositionId, userPositionInput } })
	}

	const columns = useCommunityMembersColumns(query)
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()

	const variables = { organizationId, limit: 12, filters }
	const { loading, data, refetch, fetchMore } = useGetCommunityMembersListQuery({
		variables
	})
	useDefaultFilter({ refetch })

	const { nodes = [], cursor = '', hasNextPage = false, count = 0 } = data?.getCommunityMembersList ?? {}

	return (
		<DataGridGQL
			emptyStateLabel={t('communityMembers:dataGrid.empty')}
			columns={columns}
			setIsAllRowsSelected={setIsAllRowsSelected}
			isAllRowsSelected={isAllRowsSelected}
			dataList={nodes}
			isLoading={loading}
			fetchMore={fetchMore}
			cursor={cursor}
			hasNextPage={hasNextPage}
			title={t('communityMembers:title', { count })}
			Icon={PeopleIcon}
			toolbar={
				<CommunityMembersFilterBar
					isAllRowsSelected={isAllRowsSelected}
					refetch={refetch}
					dataList={nodes}
					count={count}
				/>
			}
		/>
	)
}
