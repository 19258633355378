import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type AddRiskEntryMutationVariables, useAddRiskEntryMutation } from '@gqlTypes'
import { FormHelperText, Stack, Typography } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { RiskRadioGroup } from './RiskRadioGroup'
import InputTextField from '@ui/form/inputTextField'

interface Props {
	positionRiskId: string
	positionId: string
}

interface Render {
	onChange: (...event: unknown[]) => void
	value: string
}

export const RiskUpdator = ({ positionRiskId, positionId }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const defaultValues = {
		comment: '',
		riskId: ''
	}
	const formMethods = useForm({ mode: 'onChange', defaultValues })
	const { handleSubmit, formState } = formMethods

	const { onCompleted, onError } = useGqlSnackBar('risk:modal.updator')

	const [addRisk, { loading: isSaving }] = useAddRiskEntryMutation({ onCompleted, onError })

	const onSubmit = async (data: AddRiskEntryMutationVariables): Promise<void> => {
		await addRisk({ variables: { ...data, userPositionId: positionId } })
	}
	const errors = formState?.errors ?? {}

	return (
		<FormProvider {...formMethods}>
			<form>
				<Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>{t('risk:modal.updator.title')}</Typography>
				<InputTextField
					multiline
					placeholder={t('risk:modal.updator.placeholder')}
					name={'comment'}
					required
					error={errors.comment}
				/>
				<Controller
					name={'riskId'}
					rules={{
						validate: (message: string) => (message === '' ? t('form:errors.required') : true)
					}}
					render={({ onChange, value }: Render) => {
						return (
							<>
								<Stack direction="row" alignItems="center" spacing={2} mt={2}>
									<Typography>{t('risk:modal.updator.label')}</Typography>

									<RiskRadioGroup onChange={onChange} value={value} positionRiskId={positionRiskId} />
									<LoadingButton loading={isSaving} sx={{ mt: 4 }} variant="contained" onClick={handleSubmit(onSubmit)}>
										{t('risk:modal.updator.update')}
									</LoadingButton>
								</Stack>
								<FormHelperText error>{errors?.riskId?.message ?? ''}</FormHelperText>
							</>
						)
					}}
				/>
			</form>
		</FormProvider>
	)
}
