import { Grid, Stack, Divider } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type InterviewObjectiveInput, ObjectiveTypeId } from '@gqlTypes'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { ObjectiveTypeToggle } from '../type/ObjectiveTypeToggle'
import { QualityLeftFields } from './QualityLeftFields'
import { QuantityLeftFields } from './QuantityLeftFields'
import { QualityRightFields } from './QualityRightFields'
import { QuantityRightFields } from './QuantityRightFields'

interface Props {
	onSubmit: (input: InterviewObjectiveInput) => Promise<void>
	children?: ReactNode
	isReadOnly?: boolean
}

export const InterviewObjectivesForm = ({ onSubmit, children, isReadOnly = false }: Props): ReactElement => {
	const key = 'interview:objectives.modal'

	const { t } = useCustomTranslation()
	const { handleSubmit, control } = useFormContext()
	const isQuality = useWatch({ name: 'typeId', control }) === ObjectiveTypeId.Quality

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={5}>
					<Stack spacing={2}>
						<Stack>
							<InputTextFieldForm
								disabled={isReadOnly}
								name="label"
								label={t(`${key}.label`)}
								rules={{ required: { value: true, message: t('form:errors.required') } }}
							/>
						</Stack>
						<InputTextFieldForm
							disabled={isReadOnly}
							name="description"
							label={t(`${key}.description`)}
							multiline
							rows={3}
						/>
						<ObjectiveTypeToggle disabled={isReadOnly} />
						{isQuality ? <QualityLeftFields isReadOnly={isReadOnly} /> : <QuantityLeftFields isReadOnly={isReadOnly} />}
					</Stack>
				</Grid>

				<Grid item xs={0} sm={2}>
					<Stack sx={{ alignItems: 'center', height: '100%' }}>
						<Divider orientation="vertical" />
					</Stack>
				</Grid>

				<Grid item xs={12} sm={5}>
					{isQuality ? <QualityRightFields isReadOnly={isReadOnly} /> : <QuantityRightFields isReadOnly={isReadOnly} />}
				</Grid>
			</Grid>
			{children}
		</form>
	)
}
