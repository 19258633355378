import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ImageReturnMessage } from './tab/ImageReturnMessage'
import { MESSAGE_TYPE } from './MESSAGE_TYPE'

interface Props {
	type: string
	softSkillId: string
	decrypted: string
	score: number
	imageReturnResponseId: string
}

export const DiscussionMessageContent = ({
	type,
	softSkillId,
	decrypted,
	score,
	imageReturnResponseId
}: Props): ReactElement => {
	const { t } = useCustomTranslation()

	if ([MESSAGE_TYPE.SOFTSKILL_REQUEST, MESSAGE_TYPE.ORGASKILL_REQUEST].includes(type)) {
		return <>{t('discussionDetailPage.feedbackRequest')}</>
	}

	if (type === MESSAGE_TYPE.ORGASKILL_RESPONSE) {
		return (
			<ImageReturnMessage
				skillId={softSkillId}
				globalScore={score}
				globalComment={decrypted}
				imageReturnResponseId={imageReturnResponseId}
			/>
		)
	}

	return <>{decrypted}</>
}
