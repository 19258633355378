import { type ReactElement, useState } from 'react'
import ListBar from './ListBar'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import useTracksColumns from './useTracksColumns'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { DataGridGQL } from '../../../features/lists/components/DataGridGQL'
import { useLocation } from 'react-router-dom'
import { useGetTrackOfferListQuery, useGetTrackPositionListQuery } from '@gqlTypes'
import PeopleIcon from '@mui/icons-material/People'

export const TrackList = (): ReactElement => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('tracks:list.document.title'))
	const location = useLocation().pathname
	const isTrackOffer = location.includes('organization-offer')
	const columns = useTracksColumns(isTrackOffer)
	const variables = { cursor: '', limit: 200 }
	const { loading, data, fetchMore, refetch } = isTrackOffer
		? useGetTrackOfferListQuery({ variables })
		: useGetTrackPositionListQuery({ variables })

	const { nodes = [], cursor = '', hasNextPage = false, count = 0 } = data?.getTrackList ?? {}

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)

	return (
		<DataGridGQL
			emptyStateLabel={t('tracks:list.grid.empty')}
			columns={columns}
			setIsAllRowsSelected={setIsAllRowsSelected}
			isAllRowsSelected={isAllRowsSelected}
			dataList={nodes}
			isLoading={loading}
			fetchMore={fetchMore}
			cursor={cursor}
			hasNextPage={hasNextPage}
			title={t('tracks:list.bar.title', { count })}
			Icon={PeopleIcon}
			toolbar={<ListBar refetch={refetch} />}
		/>
	)
}
