import { useGetGeoLocationsQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import PlaceIcon from '@mui/icons-material/Place'

export const GeoLocationSelect = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data, loading } = useGetGeoLocationsQuery()
	const { errors } = useFormContext()
	const items = (data?.getGeoLocations ?? []).map((item) => ({
		value: item.id ?? '',
		label: item.label ?? ''
	}))

	const name = 'locationId'
	const hasError = errors[name] != null
	const errorMessage = errors[name]?.message ?? ''
	if (items.length === 1) return null

	return (
		<TableLayoutRow translationKey="offers:details.main.location">
			<AutocompleteSelect
				name={name}
				items={items}
				loading={loading}
				rules={{ required: t('form:errors.required') }}
				hasError={hasError}
				errorMessage={errorMessage}
				iconComponent={<PlaceIcon />}
			/>
		</TableLayoutRow>
	)
}
