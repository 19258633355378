import { type GetInterviewObjectivesQuery } from '@gqlTypes'
import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { InterivewObjectiveRow } from './InterviewObjectiveRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	id: string
	label: string
	interviewId: string
	status: GetInterviewObjectivesQuery['getInterviewObjectives'][0]['status']
	quantity: GetInterviewObjectivesQuery['getInterviewObjectives'][0]['quantity']
	isShortTerme: boolean
	isReadOnly: boolean
}

export const InterivewObjectiveQuantityRow = ({
	id,
	label,
	quantity,
	status,
	interviewId,
	isShortTerme,
	isReadOnly
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { completion, tendency, value, target, unit, nextTarget, reevaluatedTarget, previousCompletion } = quantity

	return (
		<InterivewObjectiveRow
			id={id}
			label={label}
			status={status}
			completion={completion}
			tendency={tendency}
			previousCompletion={previousCompletion}
			interviewId={interviewId}
			isShortTerme={isShortTerme}
			isReadOnly={isReadOnly}
			targetComponent={
				<Stack direction={'row'} gap={1} alignItems={'center'}>
					<Typography fontWeight={'bold'}>{value}</Typography>
					<Typography sx={{ fontSize: 40, color: 'grays.gray2' }}>/</Typography>
					<Typography fontWeight={'bold'}>{target}</Typography>
					<Typography>{quantity.unit}</Typography>
				</Stack>
			}
			nextTargetComponent={
				<Stack direction={'row'} gap={1} alignItems={'center'}>
					<Typography>{t('interview:objectives.list.nextTarget.label')}</Typography>
					{nextTarget != null ? (
						<>
							<Typography fontWeight={'bold'}>{nextTarget}</Typography>
							<Typography>{unit}</Typography>
						</>
					) : (
						<Typography>-</Typography>
					)}
				</Stack>
			}
			reevaluatedTargetComponent={
				reevaluatedTarget != null ? (
					<Stack direction={'row'} gap={1} alignItems={'center'}>
						<Typography>{t('interview:objectives.list.reevaluatedTarget.label')}</Typography>
						<Typography fontWeight={'bold'}>{reevaluatedTarget}</Typography>
						<Typography>{unit}</Typography>
					</Stack>
				) : null
			}
		/>
	)
}
