import { FormProvider, useForm } from 'react-hook-form'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import DetailBar from './DetailBar'
import InformationCard from './InformationCard'
import AccessRightsCard from './AccessRightsCard'
import useUserDetails from './useUserDetails'
import { RestrictionsCard } from './RestrictionsCard'
import { Container, Grid, Stack } from '@mui/material'
import { ErrorHandler } from '#helpers/error.helper'
import { OrganizationUserDetailsTrackCard } from './positionTrack/OrganizationUserDetailsTrackCard'
import { useParams } from 'react-router-dom'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ROUTE_ORGANIZATION_USERS } from '../../../router/routes'
import { useEffect } from 'react'
import { RemoveUserAccessButton } from '@domains/userAccess/RevokeUserAccessButton'

export default function UserDetail(): JSX.Element {
	const { t } = useCustomTranslation()
	const formMethods = useForm({ mode: 'onChange' })
	const { control, getValues, errors, reset } = formMethods
	const { userId } = useParams<{ userId: string }>()
	const { defaultValues, isLoading, isSaving, saveUser, roles, availablePositions, availableOffers, managers } =
		useUserDetails()

	const handleSubmit = (): void => {
		;(async () => {
			const data = getValues()
			await saveUser(data)
		})().catch(ErrorHandler)
	}

	const hasDeleteAction = userId !== 'new'

	useEffect(() => {
		reset({ managerId: defaultValues?.managerId })
	}, [defaultValues])
	return (
		<Container maxWidth="xl">
			<FormProvider {...formMethods}>
				<form>
					<DetailBar
						formIsValid
						isSaving={isSaving}
						handleSubmit={handleSubmit}
						title={t('organizationUsers:details.bar.title')}
						backTooltip={t('organizationUsers:list.bar.title')}
						save={t('organizationUsers:details.bar.button.save')}
						route={ROUTE_ORGANIZATION_USERS}
					>
						{hasDeleteAction ? <RemoveUserAccessButton userId={userId} /> : null}
					</DetailBar>
					<LocalLoader withPadding load={isLoading}>
						<Grid container spacing={3} mb={3} mt={0}>
							<Grid item xs={6}>
								<Stack spacing={3}>
									<InformationCard
										control={control}
										defaultValues={defaultValues}
										errors={errors}
										managers={managers}
									/>
									<OrganizationUserDetailsTrackCard {...{ availablePositions, userId, saveUser }} />
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack spacing={3}>
									<AccessRightsCard control={control} defaultValues={defaultValues} roles={roles} errors={errors} />
									<RestrictionsCard
										control={control}
										defaultValues={defaultValues}
										availableOffers={availableOffers}
										availablePositions={availablePositions}
									/>
								</Stack>
							</Grid>
						</Grid>
					</LocalLoader>
				</form>
			</FormProvider>
		</Container>
	)
}
