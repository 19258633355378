import { type TranslatedString } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import CheckBoxField from '@ui/form/CheckBoxField'
import { type FC } from 'react'
import { type Control, type FieldValues } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { TranslatedMessageField } from './TranslatedMessageField'

interface Props {
	message?: TranslatedString
	control: Control<FieldValues>
	watch: (field: string) => boolean
	hasMessageDefaultValue: boolean
}

export const InterviewMessageFields: FC<Props> = ({ watch, message, control, hasMessageDefaultValue }) => {
	const { t } = useCustomTranslation()
	const isDefaultInterviewCreationMessage = watch('isDefaultInterviewCreationMessage')
	const isDisplay =
		isDefaultInterviewCreationMessage === undefined ? hasMessageDefaultValue : !isDefaultInterviewCreationMessage

	return (
		<>
			<TableLayoutRow
				title={t('tracks:details.formFields.interviewAssessedCreationMessage.default.title')}
				tooltip={t('tracks:details.formFields.interviewAssessedCreationMessage.default.tooltip')}
			>
				<CheckBoxField
					defaultValue={!hasMessageDefaultValue}
					control={control}
					name="isDefaultInterviewCreationMessage"
				/>
			</TableLayoutRow>
			{isDisplay ? (
				<TranslatedMessageField
					translationKey="tracks:details.formFields.interviewAssessedCreationMessage"
					fieldName="interviewCreationMessage"
					control={control}
					defaultValue={message}
				/>
			) : null}
		</>
	)
}
