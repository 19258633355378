import { type ReactElement } from 'react'
import { Tab, Tabs } from '@mui/material'
import useRoleContext from '@shared/hooks/useRoleContext'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Link, useLocation } from 'react-router-dom'
import { TopBar } from '@ui/core/TopBar'
import { useMeQuery } from '@gqlTypes'
import { type Tab as MenuTab } from '@shared/components/layout/menus/menus'

interface Props {
	subMenuTabs: MenuTab[]
	fullName: string
	backRoute: string
	backToolTip: string
}

export const TabBar = ({ subMenuTabs, backRoute, backToolTip, fullName }: Props): ReactElement => {
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const isBrandFeedbackAnonymous = data?.me?.organization?.isBrandFeedbackAnonymous ?? false

	const roleContext = useRoleContext()
	const { t } = useCustomTranslation()
	const location = useLocation()
	const candidatesRole = roleContext.tabs.candidate as string[]

	const tabs = subMenuTabs.reduce((acc: MenuTab[], nextTab) => {
		if (isBrandFeedbackAnonymous && nextTab.id.includes('returnOrga')) return acc
		return candidatesRole.includes(nextTab.id) ? [...acc, nextTab] : acc
	}, [])

	let currentTabIndex = tabs.findIndex((tab) => tab.path === location.pathname)
	const ArrayOfIsInfoTab = tabs.map((tab) => tab.id.endsWith('info'))
	const hasInfoTab = ArrayOfIsInfoTab.includes(true)
	if (currentTabIndex === -1 && hasInfoTab) currentTabIndex = ArrayOfIsInfoTab.indexOf(true)

	return (
		<TopBar title={fullName} backRoute={backRoute} backToolTip={backToolTip}>
			<Tabs value={currentTabIndex} centered indicatorColor="secondary">
				{tabs.map((tab) => (
					<Tab
						iconPosition="start"
						key={tab.id}
						component={Link}
						to={tab.path}
						icon={tab.icon}
						label={t(tab.translationKey)}
						sx={{ color: '#0A2E56' }}
					/>
				))}
			</Tabs>
		</TopBar>
	)
}
