import InputTextField from '@ui/form/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: string
}

export default function DefaultInterviewLabelField({ control, defaultValue }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.defaultInterviewLabel.title')}
			tooltip={t('tracks:details.formFields.defaultInterviewLabel.tooltip')}
		>
			<InputTextField control={control} name="defaultInterviewLabel" defaultValue={defaultValue} multiline />
		</TableLayoutRow>
	)
}
