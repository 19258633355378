import CheckBoxField from './fields/CheckBoxField'
import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import ListField from './fields/ListField'
import { type Role } from './role'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Control, type DeepMap, type FieldError, type FieldValues } from 'react-hook-form'
import { type GetUserQuery } from '@gqlTypes'

interface Props {
	control: Control
	defaultValues?: GetUserQuery['getUser']
	roles: Role[]
	errors: DeepMap<FieldValues, FieldError>
}

export default function AccessRightsCard({ control, defaultValues, roles, errors }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const roleItems = roles.map((role) => ({ id: role.id, name: role.name }))
	return (
		<CardLayout title={t('organizationUsers:details.cards.accessRights')}>
			<TableLayout>
				<ListField
					name="roleId"
					control={control}
					items={roleItems}
					defaultValue={defaultValues?.role?.id ?? undefined}
					errors={errors}
					required
				/>
				<CheckBoxField name="isAssignable" control={control} defaultValue={defaultValues?.isAssignable ?? undefined} />
				<CheckBoxField name="isManager" control={control} defaultValue={defaultValues?.isManager ?? undefined} />
			</TableLayout>
		</CardLayout>
	)
}
