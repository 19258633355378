import { useCustomTranslation } from '#translations/useCustomTranslation'
import {
	IntegrationType,
	useCreateJobMatchingInterviewMutation,
	useMeQuery,
	type GetPublicResumeQuery
} from '@gqlTypes'
import { ButtonWithTooltip } from '@ui/buttons/ButtonWithTooltip'
import { useState, type ReactElement } from 'react'
import { JobmatchingInterviewLink } from './JobmatchingInterviewLink'
import { NewInterviewDrawer } from '@domains/interviews/create/NewInterviewDrawer'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props {
	jobMatching: GetPublicResumeQuery['getUserOfferJobMatching'][0]['jobMatching']
	refetch: () => void
	jobSearch: GetPublicResumeQuery['getUserOfferJobMatching'][0]['jobSearch']
}

export const JobMatchingInterview = ({ jobMatching, refetch, jobSearch }: Props): ReactElement | null => {
	const { data: meData, loading: isMeLoading } = useMeQuery()
	const [createInterview, { loading }] = useCreateJobMatchingInterviewMutation()
	const { t } = useCustomTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const interview = jobMatching?.candidateInterview
	const candidateUserOfferId = jobMatching?.candidateUserOfferId ?? ''

	const hasInterviewCreationButton = (interview?.id ?? '') === ''

	if (isMeLoading) return <LocalLoader />
	if (meData == null || !jobMatching.hasOrganizationAccess) return null

	return (
		<>
			{hasInterviewCreationButton ? (
				<ButtonWithTooltip
					variant="contained"
					color="secondary"
					onClick={() => {
						setIsOpen(true)
					}}
					tooltipLabel={t('offers:jobMatching.browser.interview.create.tooltip.enabled')}
				>
					{t('offers:jobMatching.browser.interview.create.label')}
				</ButtonWithTooltip>
			) : null}
			<JobmatchingInterviewLink interview={interview} />
			<NewInterviewDrawer
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				userIntegrationInput={{ type: IntegrationType.Offer, id: candidateUserOfferId }}
				assessorId={meData.me?.id ?? ''}
				reset={refetch}
				createInterview={createInterview}
				loading={loading}
				jobSearchId={jobSearch.id}
				trackOfferId={jobMatching.trackOfferId}
			/>
		</>
	)
}
