import { type ReactElement, useState } from 'react'
import { Button, Grid, Stack, TableRow, TextField, useTheme } from '@mui/material'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import Avatar from '@mui/material/Avatar'
import { TableLayout } from '@ui/core/TableLayout'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { BooleanInput } from '@ui/core/BooleanInput'
import LinearProgress from '@ui/progress/LinearProgress'
import StarRatingInput from '@ui/core/StarRatingInput'
import { calculateCompletion } from '../../../../helper/candidacyStatus.helper'
import { useErrorHandler } from 'react-error-boundary'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import InfoValue from './InfoValue'
import { PresentationLink } from './PresentationLink'
import { Link } from '@ui/links/Link'
import { useMeQuery } from '@gqlTypes'
import { DateTime } from 'luxon'
import { FeedbackButton } from './FeedbackButton'
import { type useDetailsReturns } from '@features/details/hooks/useDetails'
import { InfoFeedbacksKPI } from './InfoFeedbacksKPI'
import i18next from 'i18next'

interface Props {
	infos: useDetailsReturns
	updateIntegration: (field: string, newValue: string | boolean) => Promise<void>
}

export const Informations = ({ infos, updateIntegration }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const theme = useTheme()
	const errorHandler = useErrorHandler()
	const { data } = useMeQuery()
	const organizationType = data?.me?.organization.type ?? ''
	const id = data?.me?.id ?? ''
	const creationDate = data?.me?.creationDate ?? ''
	const userInfo = infos.integrationInfo?.userInfo
	const timestamp = DateTime.fromISO(creationDate).toMillis()

	useDocumentTitle(t('translation:candidates.informations.title', { name: userInfo?.displayName }))

	const [isEnglish, setIsEnglish] = useState(infos.integrationInfo?.english ?? false)

	if (infos.integrationInfo == null) return null

	const informationFields = infos.integrationInfo.userInfo?.informationFields ?? []

	const publicCode = infos.integrationInfo.publicCode ?? ''
	const hasPublicCode = publicCode != null && publicCode !== ''
	const location = infos.integrationInfo.location
	const slug = userInfo?.slug ?? ''
	const urlPrefix = import.meta.env.VITE_WEB_PUBLIC_URL
	const initials = userInfo?.initials ?? ''
	const email = infos.integrationInfo.userInfo?.email ?? ''

	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.profileImage')} sx={{ height: 300 }}>
					<InfoCardContent
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Avatar
							sx={{
								width: 150,
								height: 150,
								marginBottom: 34,
								backgroundColor: theme.palette.secondary.main,
								fontSize: 50
							}}
							src={infos.userInfo?.photoURL ?? ''}
						>
							{initials !== '' ? initials : null}
						</Avatar>
					</InfoCardContent>
				</CardLayout>
			</Grid>
			<Grid item xs={5}>
				<CardLayout
					title={t('translation:candidates.informations.cardTitle.generalInfos')}
					footer={
						<Stack direction="row" spacing={1}>
							<Link isExternal url={`${urlPrefix}resume/user/${userInfo?.userId}/iframe/${i18next.language}?`}>
								<Button variant="contained">{t('translation:candidates.informations.resume')}</Button>
							</Link>

							<FeedbackButton senderId={id} recipientSlug={slug} senderCreationDateTimeStamp={timestamp} />
						</Stack>
					}
				>
					<TableLayout>
						<InfoValue title={t('translation:candidates.commons.mobile')} text={userInfo?.mobile ?? ''} />
						<InfoValue
							title={t('translation:candidates.commons.mail')}
							text={userInfo?.email ?? undefined}
							url={`mailto:${email}`}
						/>
						{informationFields
							.filter(
								({ id }) =>
									!['email', 'mobile', 'publicTitle', 'currentCompany', 'currentFunction', 'currentSector'].some(
										(idToIgnore) => id.includes(idToIgnore)
									)
							)
							.map(({ id, label, value }) => (
								<InfoValue key={id} title={label} text={value} labelSize="40%" />
							))}
						{hasPublicCode && <InfoValue title={t('translation:candidates.commons.publicCode')} text={publicCode} />}
						<InfoValue title={t('candidates.informations.publicTitle')} text={infos.userInfo?.publicTitle ?? ''} />
						<InfoValue title={t(`candidates.informations.${organizationType}.location`)} text={location ?? ''} />
						<InfoValue title={t('candidates.informations.currentCompany')} text={infos.userInfo?.currentCompany} />
						<InfoValue
							title={t('candidates.informations.currentFunction')}
							text={infos.userInfo?.currentFunctionObject?.name}
						/>
						<InfoValue
							title={t('candidates.informations.currentSector')}
							text={infos.userInfo?.currentSectorObject?.name ?? ''}
						/>
					</TableLayout>
				</CardLayout>
			</Grid>
			<Grid item xs={4}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.candidacyStatus')}>
					<TableLayout>
						<InfoValue
							title={t('translation:candidates.commons.status')}
							text={infos.integrationInfo?.statusObject?.label ?? ''}
						/>
						<InfoValue title={t('translation:candidates.commons.profile')}>
							<LinearProgress
								compact
								withLabel
								labelPosition="left"
								value={calculateCompletion(infos.integrationInfo?.steps)}
								variant="determinate"
							/>
						</InfoValue>
						<InfoValue title={t('translation:candidates.commons.selfEvaluation')}>
							<StarRatingInput
								readOnly
								value={
									infos.integrationInfo?.alignment?.length != null ? infos.integrationInfo?.alignment?.length - 1 : null
								}
							/>
						</InfoValue>
						<InfoValue title={t('translation:candidates.commons.interviewEvaluation')}>
							<StarRatingInput
								readOnly
								value={infos.integrationInfo?.interview?.score != null ? infos.integrationInfo?.interview?.score : null}
							/>
						</InfoValue>
						<InfoFeedbacksKPI indicators={infos.userInfo?.indicators} />
					</TableLayout>
				</CardLayout>
			</Grid>
			<Grid item xs={6}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.video')}>
					<InfoCardContent
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<PresentationLink link={infos.userInfo?.link ?? ''} />
					</InfoCardContent>
				</CardLayout>
			</Grid>
			<Grid item xs={6}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.specificInfos')}>
					<InfoCardContent
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<TableLayout>
							<TableRow>
								<InfoLayoutLabel>{t('translation:candidates.informations.parcour')}</InfoLayoutLabel>
								<InfoLayoutValue>
									<BooleanInput
										value={isEnglish}
										label={t('translation:candidates.informations.english')}
										onChange={() => {
											updateIntegration('english', !isEnglish)
												.then(() => {
													setIsEnglish(!isEnglish)
												})
												.catch(errorHandler)
										}}
									/>
								</InfoLayoutValue>
							</TableRow>
						</TableLayout>
						<TextField
							label={t('translation:candidates.informations.note')}
							variant="outlined"
							multiline
							rows={4}
							fullWidth
							InputLabelProps={{ shrink: true }}
							placeholder={t('translation:candidates.informations.notePlaceHolder')}
							defaultValue={infos.integrationInfo?.comment}
							onBlur={async (event) => {
								await updateIntegration('comment', event.target.value)
							}}
						/>
					</InfoCardContent>
				</CardLayout>
			</Grid>
		</Grid>
	)
}
