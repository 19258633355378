import { type ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useCloseOfferMutation } from '@gqlTypes'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'

interface Props {
	setIsOpen: (state: boolean) => void
	route: string
	offername: string
	jobMatchingId: string
	listRoute: string
}

export const ConfirmedContent = ({
	setIsOpen,
	route,
	offername,
	jobMatchingId,
	listRoute
}: Props): ReactElement | null => {
	const history = useHistory()
	const { t } = useCustomTranslation()
	const translationKey = 'offers:jobMatching.browser.popup.selected.validated'
	const { onError } = useGqlSnackBar(translationKey)
	const [closeOffer] = useCloseOfferMutation({ variables: { jobMatchingId }, onError })

	const handleNext = (): void => {
		setIsOpen(false)
		history.push(route)
	}

	return (
		<>
			<img src="/assets/img/selected_congratulation.png" style={{ objectFit: 'contain', height: 350 }} />
			<Typography fontWeight="bold" fontSize={30} color={'secondary'}>
				{t(`${translationKey}.title`)}
			</Typography>
			<Typography fontSize={20}>{t(`${translationKey}.subtitle`)}</Typography>
			<Typography fontSize={20}>{t(`${translationKey}.subtitle2`, { offername })}</Typography>
			<Stack direction="row" justifyContent="center" gap={2}>
				<Button variant="contained" onClick={handleNext}>
					{t(`${translationKey}.keep`)}
				</Button>
				<Button
					variant="contained"
					onClick={async () => {
						await closeOffer()
						setIsOpen(false)
						history.push(listRoute)
					}}
					color={'secondary'}
				>
					{t(`${translationKey}.close`)}
				</Button>
			</Stack>
		</>
	)
}
