import { type ReactElement, useState } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { BooleanUpdater } from '../BooleanUpdater'
interface Props {
	onValidate: (field: string, newValue: string | boolean) => void
}

export const EnglishUpdateSection = ({ onValidate }: Props): ReactElement => {
	const [isEnglish, setIsEnglish] = useState('')
	const { t } = useCustomTranslation()

	return (
		<BooleanUpdater
			onValidate={onValidate}
			value={isEnglish}
			setvalue={setIsEnglish}
			title={t('modal:massUpdate.english')}
			field="english"
		/>
	)
}
