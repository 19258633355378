import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useState, type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { SkillComment } from './SkillComment'
import { useMyBottomFeedbackResponsesQuery } from '@gqlTypes'
import { Card, Stack, Typography, IconButton, Container, Alert } from '@mui/material'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

export const BottomSkillComments = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { onError } = useGqlSnackBar('translation:myProfileLoad')
	const { data, loading: isLoading, error } = useMyBottomFeedbackResponsesQuery({ onError })
	const [isTooltipOpen, setIsTooltipOpen] = useState(false)
	const responses = data?.me?.bottomFeedbackResponses ?? []

	if (isLoading) return <LocalLoader />
	if (error != null) return null

	return (
		<Card sx={{ width: 900, marginBottom: 5, marginTop: 2, paddingBottom: 2 }}>
			<Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2 }}>
				<Typography
					color="primary"
					variant="h4"
					sx={{
						paddingX: 2,
						fontSize: 20,
						fontWeight: 700
					}}
				>
					{t('translation:bottomSkillsCardTitle')}
				</Typography>
				<IconButton
					onClick={() => {
						setIsTooltipOpen(!isTooltipOpen)
					}}
				>
					<InfoOutlinedIcon />
				</IconButton>
			</Container>
			<Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{isTooltipOpen ? (
					<Alert severity="info" variant="outlined" sx={{ width: '90%', marginBottom: 2 }}>
						{t('translation:bottomSkillsCardTooltip')}
					</Alert>
				) : null}
			</Stack>
			<Stack direction="row" flexWrap="wrap" paddingLeft={4} paddingRight={4}>
				{responses.map((response) => (
					<SkillComment sx={{ width: '50%', padding: 2 }} response={response} key={response.id} />
				))}
			</Stack>
		</Card>
	)
}
