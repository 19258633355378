import { type ReactElement, useCallback, useState } from 'react'
import { buildCSV } from '../../../../helper/csv.helper'
import { getRowsData } from '../../../../helper/massActions.helper'
import IconButton from '@mui/material/IconButton'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import { Tooltip } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useErrorHandler } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useFilters } from '@shared/hooks/useFilters'
import { useOrganization } from '@domains/organization/useOrganization'

interface Props {
	isAllRowsSelected: boolean
	integrationType: string
	integrationName: string
	dataList: unknown[]
}

export const ExportCsv = ({ isAllRowsSelected, integrationType, integrationName, dataList }: Props): ReactElement => {
	const { organizationId } = useOrganization()
	const { t } = useCustomTranslation()
	const errorHandler = useErrorHandler()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: unknown[] }) => selectedRows)
	const filters = useFilters()

	const [isLoading, setIsLoading] = useState(false)

	const exportCSV = useCallback(async () => {
		setIsLoading(true)
		buildCSV(getRowsData(selectedRows, dataList), t)
		setIsLoading(false)
	}, [
		errorHandler,
		isAllRowsSelected,
		organizationId,
		selectedRows,
		filters,
		t,
		dataList,
		integrationType,
		integrationName
	])

	return (
		<div>
			<Tooltip title={t('modal:exportCSV.tooltip')}>
				<IconButton
					onClick={async () => {
						await exportCSV()
					}}
					disabled={selectedRows.length < 1 || isLoading}
				>
					{isLoading ? <LocalLoader /> : <SystemUpdateAltIcon />}
				</IconButton>
			</Tooltip>
		</div>
	)
}
