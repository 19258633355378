import InputSelectField from '@ui/form/InputSelectField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Control, type FieldErrors } from 'react-hook-form'

interface Props {
	control: Control
	errors: FieldErrors
	defaultValue?: string
	items: { id: string; name: string }[]
}

export default function TypeField({ control, errors, defaultValue, items }: Props): JSX.Element {
	const { t } = useCustomTranslation()

	const rules = {}

	return (
		<>
			{items != null && (
				<TableLayoutRow
					title={t('tracks:details.formFields.type.title')}
					tooltip={t('tracks:details.formFields.type.tooltip')}
				>
					<InputSelectField
						name="type"
						items={items}
						control={control}
						rules={rules}
						errors={errors}
						defaultValue={defaultValue}
						displayKey="name"
					/>
				</TableLayoutRow>
			)}
		</>
	)
}
