import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetUserFunctionsQuery, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { HardSkillFilter } from './HardSkillFilter'

export const UserFunctionAndSkillFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useGetUserFunctionsQuery()
	const { data: dataMe, loading: isMeLoading } = useMeQuery()
	const hasFunctions = (dataMe?.me?.organization?.matchingRestrictions?.userFunctions ?? []).length > 1

	if (isLoading || data?.getUserFunctions == null || isMeLoading || dataMe?.me == null) return null
	const userFunctions = data.getUserFunctions.map((userFunction) => ({
		value: userFunction?.id ?? '',
		label: userFunction?.name ?? ''
	}))

	return (
		<>
			{hasFunctions ? (
				<InputFilter keyName="userFunctionId" operation={filterOperation.EQUAL}>
					<AutoCompleteFilter
						options={userFunctions}
						label={t('offers:resumeLibrary.filters.userFunction.label')}
						allValueLabel={t('offers:resumeLibrary.filters.userFunction.allLabel')}
						width="100%"
					/>
				</InputFilter>
			) : null}

			<HardSkillFilter />
		</>
	)
}
