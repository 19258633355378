import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { DiscussionContent } from '../DiscussionContent'
import { type useDetailsReturns } from '@features/details/hooks/useDetails'
import { type ReactElement } from 'react'
import { useGetDiscussionUserByUserFromOrganizationQuery } from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props {
	infos: useDetailsReturns
}

export const Discussion = ({ infos }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const displayName = infos.integrationInfo?.userInfo?.displayName
	const photoURL = infos.integrationInfo?.userInfo?.photoURL ?? ''
	const userId = infos.integrationInfo?.userInfo?.userId ?? ''

	useDocumentTitle(t('translation:candidates.discussion.title', { name: displayName }))

	const { loading, data, refetch } = useGetDiscussionUserByUserFromOrganizationQuery({ variables: { userId } })
	if (loading) return <LocalLoader />

	const discussionUser = data?.getDiscussionUserByUserFromOrganization

	return (
		<DiscussionContent
			discussionUser={discussionUser}
			userId={infos.userInfo?.userId ?? ''}
			displayName={displayName ?? ''}
			photoURL={photoURL}
			refetch={refetch}
		/>
	)
}
