import { type GetJobSearchListQuery } from '@gqlTypes'
import { Stack, Typography } from '@mui/material'
import { UserCard } from '@shared/components/cards/UserCard'
import { UserAvatar } from '@ui/UserAvatar'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { OffersPath } from '../../OffersRouter'
import { generateRoute } from '../../../../router/routes'
import { ChipList } from '@shared/components/ChipList'
import PlaceIcon from '@mui/icons-material/Place'
import EventIcon from '@mui/icons-material/Event'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import { CardIconLabel } from '../../CardIconLabel'

interface Props {
	jobSearch: GetJobSearchListQuery['getJobSearchList']['nodes'][0]
	limit: number
}

export const JobSearchCard = ({ jobSearch, limit }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const {
		userOffer,
		jobDurationLabels,
		topMicroHardSkills,
		topMacroHardSkills,
		topSoftSkills,
		workArrangements,
		offerType,
		availabilityLabel,
		id,
		departmentsLabel
	} = jobSearch
	const { userInfo, orgaOffer } = userOffer
	const publicTitle = userInfo?.publicTitle
	const subtitle = publicTitle != null && publicTitle !== '' ? publicTitle : (orgaOffer?.label ?? '')

	const microHardSkillLabels = topMicroHardSkills.map((skill) => skill.label).join(', ')

	const workArrangementLabels = workArrangements
		.map(({ label }) => label)
		.filter((str) => str !== '')
		.join(' • ')

	return (
		<UserCard
			userRef={userInfo}
			linkLabel={t('offers:resumeLibrary.detailsButton')}
			to={generateRoute(OffersPath.resumeLibraryBrowser, {
				jobSearchId: id
			})}
			navigationState={{ isFromApp: true }}
			avatarComponent={<UserAvatar user={userInfo} />}
		>
			<Stack spacing={1}>
				<Typography sx={{ fontSize: '16px', color: 'grays.gray5' }}>{subtitle}</Typography>
				<ChipList
					items={topMacroHardSkills}
					selectedItems={topMacroHardSkills.map(({ id }) => id)}
					chipProps={{ size: 'small' }}
					gap={0.5}
				/>
				<Typography sx={{ fontSize: '14px', color: 'grays.gray3', fontStyle: 'italic' }}>
					{microHardSkillLabels}
				</Typography>
				<ChipList
					items={topSoftSkills}
					selectedItems={topSoftSkills.map(({ id }) => id)}
					chipProps={{ size: 'small' }}
					gap={0.5}
				/>
				<CardIconLabel
					icon={<PlaceIcon sx={{ width: 18 }} />}
					label={[workArrangementLabels, departmentsLabel].filter((str) => str !== '').join(', ')}
				/>
				<CardIconLabel
					icon={<LocationCityIcon sx={{ width: 18 }} />}
					label={[offerType.label, ...jobDurationLabels].filter((str) => str !== '').join(', ')}
				/>
				<CardIconLabel icon={<EventIcon sx={{ width: 18 }} />} label={availabilityLabel} />
			</Stack>
		</UserCard>
	)
}
