import { InfoCardContent } from '@ui/core/InfoCardContent'
import CardLayout from '@ui/core/CardLayout'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'

interface Props {
	profilingId?: string
	profileId?: string
}

export const CommunicationKeyCard = ({ profilingId = '', profileId = '' }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const content = t(`profileProfiling:profileProfiling.${profilingId}-${profileId}.howToCommunicate`)

	if (profileId === '' || profilingId === '') return null

	return (
		<CardLayout title={t('translation:candidates.profile.communicationKeys.title')}>
			<InfoCardContent>{content}</InfoCardContent>
		</CardLayout>
	)
}
