import { type ReactElement } from 'react'
import { TextFilter } from './TextFilter'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	firstNameKey?: string
	lastNameKey?: string
}

export const Fullname = ({
	firstNameKey = 'userInfo.firstnameLowercase',
	lastNameKey = 'userInfo.lastnameLowercase'
}: Props): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<>
			<TextFilter keyName={firstNameKey} label={t('lists:filter.firstname')} merged="right" />
			<TextFilter keyName={lastNameKey} label={t('lists:filter.lastname')} merged="left" />
		</>
	)
}
