import TrackButton from './TrackButton'
import { ROUTE_TRACKS_OFFER } from '../../../../router/routes'
import { useCustomTranslation } from '#translations/useCustomTranslation'

const CandidacyTrackButton = (): JSX.Element => {
	const { t } = useCustomTranslation()

	return <TrackButton title={t('tracks:list.bar.filter.candidacy')} url={ROUTE_TRACKS_OFFER} />
}

export default CandidacyTrackButton
