import { useSortQuery } from '@features/lists/hooks/useSortQuery'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { type ReactElement } from 'react'
import { FilterSortingDirection } from '@gqlTypes'
import { type ApolloQueryResult } from '@apollo/client'
import { useFilters } from '@shared/hooks/useFilters'
import { searchQuery } from '@features/lists/components/Search'
import MDIIcon from '@ui/icon/MDIIcon'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	refetch: (variables?: object) => Promise<ApolloQueryResult<unknown>>
}

export const InterviewsSorting = ({ refetch }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { filters } = useFilters()
	const { sortBy, setSortBy } = useSortQuery()

	const variables = {
		limit: 12,
		filters: filters.filter((filter) => filter.value !== 'empty'),
		cursor: '',
		searchQuery: searchQuery(),
		sortBy
	}

	const sortByDateValue = [{ fieldName: 'targetDate', direction: FilterSortingDirection.Desc }]
	const sortByNameValue = [
		{ fieldName: 'userRef.lastName', direction: FilterSortingDirection.Asc },
		{ fieldName: 'userRef.firstName', direction: FilterSortingDirection.Asc }
	]

	return (
		<ToggleButtonGroup
			size="small"
			color="primary"
			exclusive
			value={sortBy[0]?.fieldName === 'targetDate'}
			onChange={(_, isSortByDate) => {
				const sortByValue = isSortByDate === true ? sortByDateValue : sortByNameValue
				setSortBy(sortByValue)
				void refetch({ ...variables, sortBy: sortByValue })
			}}
		>
			<Tooltip title={t('lists:sort.targetDate')}>
				<ToggleButton value={true}>
					<MDIIcon name={'sort-calendar-ascending'} />
				</ToggleButton>
			</Tooltip>
			<Tooltip title={t('lists:sort.reverseFullname')}>
				<ToggleButton value={false}>
					<MDIIcon name={'sort-alphabetical-ascending'} />
				</ToggleButton>
			</Tooltip>
		</ToggleButtonGroup>
	)
}
