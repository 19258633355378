import { LabelField } from '../fields/LabelField'
import CategoryField from '../fields/CategoryField'
import TypeField from '../fields/TypeField'
import FunctionField from '../fields/FunctionField'
import UrlField from '../fields/UrlField'
import IntroductionField from '../fields/IntroductionField'
import LocationField from '../fields/LocationField'
import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import DescriptionField from '../fields/DescriptionField'
import MailField from '../fields/MailField'
import OnboardingField from '../fields/OnboardingField'
import { BrandFeedbackRequestTemplateIdField } from '../fields/BrandFeedbackRequestTemplateIdField'
import { type TrackDetailsFragment } from '@gqlTypes'
import { TrackCheckBox } from '../fields/TrackCheckBox'
import { type GqlFormErrors } from '#helpers/form.helper'
import { TrackInputSelect } from '../fields/TrackInputSelect'
import { type Control, type FieldErrors } from 'react-hook-form'
import { InterviewMessageFields } from '../fields/InterviewMessageFields'
import { TranslatedMessageField } from '../fields/TranslatedMessageField'

interface Props {
	control: Control
	watch: (field: string) => boolean
	errors: FieldErrors
	details?: TrackDetailsFragment
	categories?: { id: string; name: string }[]
	offerTypes?: { id: string; name: string }[]
	userFunctions?: { id: string; name: string }[]
	trackUseCases: { id: string; name: string }[]
	isOffer: boolean
	gqlFormErrors: GqlFormErrors
}

export default function GeneralInfoCard({
	watch,
	control,
	errors,
	details,
	categories,
	offerTypes,
	userFunctions,
	isOffer,
	trackUseCases,
	gqlFormErrors
}: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const defaultInterviewCreationAssessorMessage = details?.defaultInterviewCreationAssessorMessage ?? { fr: '', en: '' }

	return (
		<CardLayout title={t('tracks:details.cards.info')}>
			<TableLayout>
				<LabelField control={control} defaultValue={details?.label} />
				<CategoryField
					control={control}
					errors={errors}
					defaultValue={details?.category ?? ''}
					items={categories ?? []}
				/>
				<TrackInputSelect
					fieldName="customTranslationKey"
					control={control}
					errors={errors}
					defaultValue={details?.customTranslationKey ?? ''}
					items={trackUseCases ?? []}
				/>
				{isOffer && (
					<>
						<TypeField control={control} errors={errors} defaultValue={details?.type ?? ''} items={offerTypes ?? []} />
						<FunctionField
							control={control}
							errors={errors}
							defaultValue={details?.userFunction ?? ''}
							items={userFunctions ?? []}
						/>
					</>
				)}
				<UrlField control={control} defaultValue={details?.url ?? undefined} />
				<DescriptionField control={control} defaultValue={details?.description ?? undefined} />
				<IntroductionField control={control} defaultValue={details?.introduction ?? undefined} />
				<LocationField control={control} defaultValue={details?.locations} />
				<MailField control={control} defaultValue={details?.notifyTo} />
				<OnboardingField
					control={control}
					defaultValue={details?.onboardingCodeObject?.code}
					gqlFormErrors={gqlFormErrors}
				/>
				<BrandFeedbackRequestTemplateIdField control={control} defaultValue={details?.brandFeedbackRequestTemplateId} />
				<InterviewMessageFields
					hasMessageDefaultValue={details?.hasInterviewCreationMessage ?? true}
					watch={watch}
					control={control}
					message={details?.interviewCreationMessage}
				/>
				<TranslatedMessageField
					translationKey="tracks:details.formFields.interviewAssessorCreationMessage"
					fieldName="defaultRequest"
					control={control}
					defaultValue={details?.defaultRequest ?? defaultInterviewCreationAssessorMessage}
				/>
				<TrackCheckBox control={control} fieldName="hasVideo" details={details} />
				<TrackCheckBox control={control} fieldName="isMobileAppRequired" details={details} />
				<TrackCheckBox control={control} fieldName="hasCareerSearch" details={details} />
			</TableLayout>
		</CardLayout>
	)
}
