import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { type CSSProperties, type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	src: string
	style: CSSProperties
}

export const VideoEmbed = ({ src, style }: Props): ReactElement => {
	const theme = useTheme()
	const { t } = useCustomTranslation()
	const url = new URL(src)

	let videoId = ''

	if (url.hostname.endsWith('youtube.com') && url.searchParams.has('v')) {
		videoId = url.searchParams.get('v') ?? ''
	} else if (url.hostname.endsWith('youtube.com') && url.pathname.startsWith('/embed/')) {
		videoId = url.pathname.replace(/^\/embed\//, '')
	} else if (url.hostname === 'youtu.be') {
		videoId = url.pathname.replace(/^\//, '')
	}

	const embedUrl = `https://www.youtube.com/embed/${videoId}`

	return (
		<div style={{ width: '100%', ...style }}>
			{videoId !== '' ? (
				<iframe
					title="video"
					src={embedUrl}
					width="100%"
					height="100%"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			) : (
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						background: theme.palette.grays.gray1,
						borderRadius: 30
					}}
				>
					<div
						style={{
							fontSize: '3em',
							color: theme.palette.grays.gray2
						}}
					>
						<VideocamOffIcon fontSize="inherit" />
					</div>
					<Typography>{t('translation:videoEmbed.notSupported')}</Typography>
					<Typography variant="body2" component="a" href={src} target="_blank">
						{t('translation:videoEmbed.notSupportedLink', {
							url: src
						})}
					</Typography>
				</div>
			)}
		</div>
	)
}
