import { type ReactElement, useCallback, useState } from 'react'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import { useSelector } from 'react-redux'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { MassFeedbackModal } from './MassFeedbackModal'
import { getRowsData } from '../../../helper/massActions.helper'
import { IconAction } from '@ui/icon/IconAction'
import { type IntegrationCategory, type TrackType } from '@gqlTypes'

interface Props {
	isAllRowsSelected: boolean
	dataList: { id: string }[]
	trackType: TrackType
	count: number
	integrationCategory: IntegrationCategory
}

export const MassFeedback = ({
	isAllRowsSelected,
	dataList,
	trackType,
	count,
	integrationCategory
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const [isOpen, setIsOpen] = useState(false)

	const getIntegrationIds = useCallback(() => {
		return getRowsData(selectedRows, dataList).map((row: { id: string }) => row.id)
	}, [selectedRows, dataList])

	return (
		<>
			<IconAction
				tooltip={t('modal:massFeedback.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<LiveHelpOutlinedIcon />
			</IconAction>
			<MassFeedbackModal
				open={isOpen}
				setOpen={setIsOpen}
				userIntegrationsIds={isAllRowsSelected ? [] : getIntegrationIds()}
				trackType={trackType}
				count={isAllRowsSelected ? count : getIntegrationIds().length}
				integrationCategory={integrationCategory}
			/>
		</>
	)
}
