import CardLayout from '@ui/core/CardLayout'
import { type ReactElement } from 'react'
import { Divider } from '@mui/material'
import { TopSoftSkillSelector } from './TopSoftSkillSelector'
import { TopHardSkill } from './TopHardSkill'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LanguagesSelector } from './LanguagesSelector'

export const OfferExpectedSkillsCard = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	return (
		<CardLayout
			noDivider
			title={t('offers:details.expectedSkills.title')}
			tooltip={t('offers:details.expectedSkills.tooltip')}
		>
			<TopHardSkill />
			<Divider sx={{ borderStyle: 'dashed' }} />
			<TopSoftSkillSelector />
			<Divider />
			<LanguagesSelector />
		</CardLayout>
	)
}
