import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputTextField from '@ui/form/inputTextField'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UploadPdfField } from './UploadPdfField'
interface Props {
	contentPdfUrl: string
}

export const OfferContentCard = ({ contentPdfUrl }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { control } = useFormContext()

	return (
		<CardLayout noDivider title={t('offers:details.content.title')} tooltip={t('offers:details.content.tooltip')}>
			<TableLayout>
				<TableLayoutRow translationKey="offers:details.content.pdf">
					<UploadPdfField
						name="contentPdfPath"
						directoryPath="tmp/offers"
						addLabel={t('offers:details.content.pdf.button.add')}
						updateLabel={t('offers:details.content.pdf.button.edit')}
						pdfUrl={contentPdfUrl}
					/>
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.content.url">
					<InputTextField control={control} name="url" />
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.content.description">
					<InputTextField control={control} name="description" multiline />
				</TableLayoutRow>
			</TableLayout>
		</CardLayout>
	)
}
