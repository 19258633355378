import { type ReactElement } from 'react'
import { type GraphDimension, type UserRef } from '@gqlTypes'
import { Stack, Typography, Divider, Box } from '@mui/material'
import MDIIcon from '@ui/icon/MDIIcon'
import { UserCard } from '@shared/components/cards/UserCard'
import { GraphRadar } from '@shared/components/GraphRadar'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	userRef: UserRef
	graphDimensions: GraphDimension[]
}

export const AutoEvaluationCard = ({ userRef, graphDimensions }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<UserCard userRef={userRef}>
			<Box sx={{ minHeight: 132 }}>
				<Stack direction="row" gap={1} alignItems="center">
					<MDIIcon color="primary" name="account-tie" size={18} />
					<Typography sx={{ fontSize: '16px', color: 'primary.main' }}>
						{t('interview:progression.radar.selfEvaluation')}
					</Typography>
				</Stack>
			</Box>
			<Divider />
			<Stack alignItems="center" sx={{ minHeight: 324, pt: 1 }}>
				<GraphRadar dimensions={graphDimensions} size="small" />
			</Stack>
		</UserCard>
	)
}
