import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import clsx from 'clsx'

import { makeStyles } from 'tss-react/mui'
import { DefaultRenderCell } from './DefaultRenderCell'

/// TABLE HEAD ///

const useRowStyle = makeStyles()(() => ({
	root: {},
	cell: {},
	cellContent: {
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'center',
		width: '100%',
		height: '100%',

		'& > *': {
			flex: 1
		}
	},
	checkboxCell: {
		width: 74,
		maxWidth: 74,
		'& > $cellContent': {
			width: 'min-content',
			maxWidth: 'min-content'
		}
	},
	header: {},
	stickyHeader: {
		position: 'sticky'
	}
}))

/// TABLE ROW ///

export const DataTableRow = (props) => {
	const { selectable, selected, onSelectionChange } = props
	const { columns, rowData, rowIndex } = props
	const { classes } = useRowStyle(props)
	const { t } = useCustomTranslation()

	return (
		<TableRow className={classes.root} key={rowIndex}>
			{selectable && (
				<TableCell align="center" className={clsx(classes.checkboxCell)}>
					<div className={classes.cellContent}>
						<div>
							<Tooltip title={t('lists:dataGrid.select')}>
								<Checkbox checked={selected} onChange={(e) => onSelectionChange(e.target.checked)} />
							</Tooltip>
						</div>
					</div>
				</TableCell>
			)}
			{columns.map((column, i) => (
				<TableCell
					align={column.align}
					className={classes.cell}
					key={column.key || column.label || i}
					style={column.width ? { width: column.width } : undefined}
				>
					<div className={classes.cellContent}>
						<div>
							{column.renderCell?.(rowData, column, rowIndex) ?? <DefaultRenderCell row={rowData} column={column} />}
						</div>
					</div>
				</TableCell>
			))}
		</TableRow>
	)
}
