import { type ReactElement } from 'react'
import { Dialog, DialogContent, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useHistory } from 'react-router-dom'

interface Props {
	isOpen: boolean
	setIsOpen: (state: boolean) => void
	firstname: string
	route: string
}

export const MatchedModal = ({ isOpen, setIsOpen, firstname, route }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const history = useHistory()
	const handleClose = (): void => {
		setIsOpen(false)
		history.push(route)
	}

	if (!isOpen) return null
	const translationKey = 'offers:jobMatching.browser.popup.matched'

	return (
		<Dialog open fullWidth maxWidth="lg" onClose={handleClose} PaperProps={{ sx: { borderRadius: 3 } }}>
			<DialogContent>
				<Stack direction="column" justifyContent="center" textAlign="center" gap={2}>
					<img src="/assets/img/matched.png" style={{ objectFit: 'contain', height: 350 }} />
					<Typography fontWeight="bold" fontSize={30} color={'secondary'}>
						{t(`${translationKey}.title`)}
					</Typography>
					<Typography fontSize={20}>{t(`${translationKey}.subtitle`, { firstname })}</Typography>
					<Stack direction="row" justifyContent="center" gap={2}>
						<Button variant="contained">{t(`${translationKey}.discussion`)}</Button>
						<Button variant="contained" color={'secondary'} onClick={handleClose}>
							{t(`${translationKey}.continue`)}
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}
