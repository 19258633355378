import { type ReactElement } from 'react'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Box, Typography } from '@mui/material'
import { type SerializedSkill } from '@gqlTypes'
import { BrandIndicators } from './BrandIndicators'

interface Props {
	isLoading: boolean
	indicators: SerializedSkill[]
	isUnavailable: boolean
	type: string
	title: string
	isShowMoreExpandedByDefault?: boolean
}

export const BrandIndicatorsCard = ({
	isLoading,
	indicators,
	isUnavailable,
	title,
	isShowMoreExpandedByDefault = false
}: Props): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<Box sx={{ pageBreakBefore: 'always' }}>
			<CardLayout title={title}>
				<LocalLoader load={isLoading}>
					{isUnavailable ? (
						<InfoCardContent>
							<Typography>{t('translation:candidates.profile.softSkills.unavailable')}</Typography>
						</InfoCardContent>
					) : (
						<TableLayout>
							<BrandIndicators {...{ isShowMoreExpandedByDefault, indicators }} />
						</TableLayout>
					)}
				</LocalLoader>
			</CardLayout>
		</Box>
	)
}
