import { type ReactElement, useState } from 'react'
import { MassUpdateModalGql } from '../components/actions/MassUpdateModalGql'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useSelector } from 'react-redux'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type IntegrationCategory, type TrackType } from '@gqlTypes'
import { type ApolloQueryResult, type FetchResult } from '@apollo/client'
import { IconAction } from '@ui/icon/IconAction'
interface Props {
	query: (variables: Record<string, unknown>) => Promise<FetchResult<unknown>>
	dataList: { id: string }[]
	isLoading: boolean
	refetch: () => Promise<ApolloQueryResult<unknown>>
	isAllRowsSelected: boolean
	count: number
	trackType?: TrackType
	integrationCategory?: IntegrationCategory
}

export const MassUpdatesGql = ({
	query,
	dataList,
	isLoading,
	refetch,
	isAllRowsSelected,
	count,
	trackType,
	integrationCategory
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const [isOpen, setIsOpen] = useState(false)
	const ids = isAllRowsSelected ? [] : selectedRows.map((index) => dataList[index].id)

	return (
		<>
			<IconAction
				tooltip={t('modal:massUpdate.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<ListAltIcon />
			</IconAction>
			<MassUpdateModalGql
				isOpen={isOpen}
				setOpen={setIsOpen}
				query={query}
				ids={ids}
				isLoading={isLoading}
				refetch={refetch}
				count={count}
				trackType={trackType}
				integrationCategory={integrationCategory}
			/>
		</>
	)
}
