import { type ReactElement } from 'react'
import { DialogTitle, Grid, IconButton, Stack, DialogContent } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useGetInterviewSoftSkillRestitutionQuery } from '@gqlTypes'
import CloseIcon from '@mui/icons-material/Close'
import { SoftSKillRestitutionKeywords } from './SoftSKillRestitutionKeywords'
import { SharedReturnList } from '@domains/feedbacks/tab/SharedReturnList'
import { SharedDetailsList } from '@domains/feedbacks/tab/SharedDetailsList'

interface Props {
	interviewId: string
	skillId: string
	handleClose: () => void
}

export const InterviewSoftSkillRestitution = ({ interviewId, skillId, handleClose }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { loading: isLoading, data } = useGetInterviewSoftSkillRestitutionQuery({ variables: { interviewId, skillId } })

	if (isLoading) return <LocalLoader style={{ height: 200 }} />
	const restitution = data?.getInterviewSoftSkillRestitution
	if (restitution == null) return null
	const { feedbacks, behaviorsAverage, topKeywordsCount, bottomKeywordsCount } = restitution

	return (
		<>
			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 5, pb: 0 }}>
				<DialogTitle sx={{ p: 0, fontWeight: 'bold', color: 'primary.main', fontSize: 24 }}>
					{t('sharedReturnTab:title')} - {restitution.softSkill.label}
				</DialogTitle>
				<IconButton aria-label="close" onClick={handleClose} color="primary">
					<CloseIcon />
				</IconButton>
			</Stack>
			<DialogContent sx={{ p: 5, pt: 2 }}>
				<Grid container spacing={10}>
					<Grid item xs={6}>
						<SharedReturnList sharedReturns={feedbacks} />
					</Grid>
					<Grid item xs={6}>
						<SharedDetailsList details={behaviorsAverage} />
						<SoftSKillRestitutionKeywords {...{ topKeywordsCount, bottomKeywordsCount }} />
					</Grid>
				</Grid>
			</DialogContent>
		</>
	)
}
