import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ROUTE_ORGANIZATION_USERS } from '../../../router/routes'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { filterOperation } from '@ui/filter/FilterForm'
import LocationFilter from '@features/lists/components/Filters/LocationsFilter'
import BusinessUnitFilter from '@features/lists/components/Filters/BusinessUnitFilter'
import { RoleFilter } from '@features/lists/components/Filters/RoleFilter'
import { useEffect } from 'react'
import { type FilterInput, useGetRolesQuery, UserExportDocument, type GetOrganizationUserListQuery } from '@gqlTypes'
import { UserImport } from '../import/UserImport'
import { useFilters } from '@shared/hooks/useFilters'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import AddIcon from '@mui/icons-material/Add'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { IconAction } from '@ui/icon/IconAction'
import { ExportGQL } from '@features/modals/components/actions/ExportGQL'

interface Props {
	isAllRowsSelected: boolean
	refetch: ({ filters }: { filters: FilterInput[] }) => void
	dataList: NonNullable<NonNullable<GetOrganizationUserListQuery['getOrganizationUserList']>['nodes']>
}

export default function ListBar({ isAllRowsSelected, refetch, dataList }: Props): JSX.Element | null {
	const { t } = useCustomTranslation()
	const { filters, setFilters } = useFilters()
	const { data, loading: isloading } = useGetRolesQuery()

	useEffect(() => {
		if (data?.getRoles == null) return

		const defaultRoles = data?.getRoles.map((role) => role?.id ?? '')
		const defaultFilters: FilterInput[] = [
			{ key: 'active', operation: filterOperation.EQUAL, value: true },
			{ key: 'roleId', operation: filterOperation.IN_ARRAY, value: defaultRoles }
		]
		if (filters.length === 0) {
			setFilters(defaultFilters)
			refetch({ filters: defaultFilters })
		}
	}, [data])

	if (isloading) return null

	return (
		<LeftBar>
			<Filters refetch={refetch}>
				<FiltersContent>
					<RoleFilter />
					<LocationFilter isBasedOnOrgaType />
					<BusinessUnitFilter isSchool />
					<Fullname firstNameKey="firstnameLowercase" lastNameKey="lastnameLowercase" />
					<TextFilter keyName="email" operation={filterOperation.EQUAL} label={t('lists:filter.email')} />
					<CheckboxInput keyName="active" translationKey="lists:filter.archived" isReversedValue />
				</FiltersContent>
			</Filters>
			<IconAction tooltip={t('organizationUsers:list.bar.button.new')} to={`${ROUTE_ORGANIZATION_USERS as string}/new`}>
				<AddIcon />
			</IconAction>
			<UserImport />
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={UserExportDocument} />
		</LeftBar>
	)
}
