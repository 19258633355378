import { type GetOfferTrackDetailsQuery, type GetPositionTrackDetailsQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import SharedProfileField from '../fields/SharedProfileField'
import SharedReturnDetailsField from '../fields/SharedReturnDetailsField'
import SharedSoftSkillsReturnField from '../fields/SharedSoftSkillsReturnField'
import SharedSoftSkillsTestField from '../fields/SharedSoftSkillsTestField'
import { type Control, type FieldErrors } from 'react-hook-form'

interface Props {
	control: Control
	errors: FieldErrors
	details?: GetOfferTrackDetailsQuery['getTrackDetails'] | GetPositionTrackDetailsQuery['getTrackDetails']
}

export default function TrackSharingCard({ control, details }: Props): JSX.Element {
	const { t } = useCustomTranslation()

	return (
		<CardLayout title={t('tracks:details.cards.sharing')}>
			<TableLayout>
				<SharedProfileField control={control} defaultValue={details?.sharingSettings?.sharedProfile} />
				<SharedSoftSkillsReturnField
					control={control}
					defaultValue={details?.sharingSettings?.sharedSoftSkillsReturn ?? undefined}
				/>
				<SharedSoftSkillsTestField
					control={control}
					defaultValue={details?.sharingSettings?.sharedSoftSkillsTest ?? undefined}
				/>
				<SharedReturnDetailsField
					control={control}
					defaultValue={details?.sharingSettings?.sharedReturnDetails ?? undefined}
				/>
			</TableLayout>
		</CardLayout>
	)
}
