import { type ReactElement, useState } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import AddIcon from '@mui/icons-material/Add'
import { IconAction } from '@ui/icon/IconAction'
import { NewInterviewDrawer } from './NewInterviewDrawer'
import { useCreateInterviewMutation } from '@gqlTypes'

interface Props {
	reset: () => void
}

export const NewInterview: React.FC<Props> = ({ reset }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [createInterview, { loading }] = useCreateInterviewMutation()

	return (
		<>
			<IconAction
				tooltip={t('drawer:interview.title')}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<AddIcon />
			</IconAction>
			<NewInterviewDrawer
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				reset={reset}
				createInterview={createInterview}
				loading={loading}
			/>
		</>
	)
}
