import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import InfoValue from './InfoValue'
import { type UserIndicatorsFragment } from '@gqlTypes'

export const InfoFeedbacksKPI = ({ indicators }: UserIndicatorsFragment): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<>
			<InfoValue
				title={t('translation:candidates.commons.feedbacks.requested')}
				text={indicators.feedbackRequestCount != null ? indicators.feedbackRequestCount.toString() : ''}
			/>
			<InfoValue
				title={t('translation:candidates.commons.feedbacks.received')}
				text={indicators.feedbackReceivedCount != null ? indicators.feedbackReceivedCount.toString() : ''}
			/>
			<InfoValue
				title={t('translation:candidates.commons.feedbacks.shared')}
				text={indicators.feedbackSentCount.toString()}
			/>
		</>
	)
}
