import { type ReactElement } from 'react'
import InputSelectField from '@ui/form/InputSelectField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { type Control, type FieldValues } from 'react-hook-form'

interface Props {
	control: Control<FieldValues>
	defaultValue?: string
}

export const BrandFeedbackRequestTemplateIdField = ({ control, defaultValue }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const rules = {}

	const { data } = useMeQuery()
	const items = data?.me?.organization.feedbackRequestTemplates ?? []

	return (
		<>
			{items != null && (
				<TableLayoutRow
					title={t('tracks:details.formFields.brandFeedbackRequestTemplateId.title')}
					tooltip={t('tracks:details.formFields.brandFeedbackRequestTemplateId.tooltip')}
				>
					<InputSelectField
						name="brandFeedbackRequestTemplateId"
						items={items}
						control={control}
						rules={rules}
						errors={{}}
						defaultValue={defaultValue}
						displayKey="label"
					/>
				</TableLayoutRow>
			)}
		</>
	)
}
