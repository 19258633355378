import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { useGetAuthorizedOrganizationsQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const AuthorizedOrganizationsAutocomplete = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data: dataOrganizations, loading: isOrganizationLoading } = useGetAuthorizedOrganizationsQuery()
	const organizations = dataOrganizations?.getAuthorizedOrganizations ?? []
	if (organizations.length === 0 || isOrganizationLoading) return null

	return (
		<TableLayoutRow translationKey="offers:details.main.organization">
			<AutocompleteSelect
				name="organizationId"
				items={organizations}
				loading={isOrganizationLoading}
				sx={{ width: '100%' }}
				rules={{ required: t('form:errors.required') }}
			/>
		</TableLayoutRow>
	)
}
