import { useCustomTranslation } from '#translations/useCustomTranslation'
import AutoCompleteFilter from '../Filters/AutoCompleteFilter'
import { type ReactElement } from 'react'
import { useGetManagersQuery } from '@gqlTypes'

interface Props {
	value: string
	onSelect: (parentId: string, fieldId: string, value: string) => void
	userPositionId: string
	field?: string
	noneLabel?: string
}

export default function ManagerSelect({
	value,
	onSelect,
	userPositionId,
	field = 'manager',
	noneLabel
}: Props): ReactElement {
	const { t } = useCustomTranslation()
	const { data } = useGetManagersQuery()
	const managers = (data?.getManagers ?? []).map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))
	const label = noneLabel ?? t('employees:cell.manager.none')

	return (
		<AutoCompleteFilter
			options={managers}
			value={value}
			onChange={(manager: string) => {
				onSelect(userPositionId, field, manager)
			}}
			noneValueLabel={label}
		/>
	)
}
