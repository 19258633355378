import { type ReactElement } from 'react'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import InputSelectTextField from '@domains/interviews/create/InputSelectTextField'
import { type Control, Controller, type DeepMap, type FieldError, type FieldValues } from 'react-hook-form'

interface Props {
	control: Control
	name: string
	defaultValue?: string
	items: Item[]
	multiple?: boolean
	errors?: DeepMap<FieldValues, FieldError>
	required?: boolean
	isClearable?: boolean
	isHiddenOnEmpty?: boolean
}

interface Item {
	id: string
	name: string
}

export default function ListField({
	isClearable,
	control,
	name,
	defaultValue,
	items,
	multiple,
	errors,
	required,
	isHiddenOnEmpty = false
}: Props): ReactElement {
	const { t } = useCustomTranslation()
	const title = t(`organizationUsers:details.formFields.${name}.title`)
	const tooltip = t(`organizationUsers:details.formFields.${name}.tooltip`)

	if (items.length === 0 && isHiddenOnEmpty) {
		return <Controller control={control} name={name} defaultValue="" render={() => <></>} />
	}

	return (
		<TableLayoutRow title={title} tooltip={tooltip}>
			<InputSelectTextField
				isClearable={isClearable}
				name={name}
				items={items}
				control={control}
				rules={{}}
				errors={errors ?? {}}
				defaultValue={defaultValue}
				displayKey="name"
				multiple={multiple}
				required={required}
			/>
		</TableLayoutRow>
	)
}
