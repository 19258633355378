import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetRolesQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'

export const RoleFilter = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data, loading: isloading } = useGetRolesQuery()
	if (isloading || data?.getRoles == null) return <></>

	const roles = data.getRoles.map((role) => ({ value: role?.id ?? '', label: role?.name ?? '' }))

	return (
		<InputFilter keyName="roleId" operation={filterOperation.IN_ARRAY}>
			<AutoCompleteFilter options={roles} label={t('lists:filter.role.label')} multiple limitTags={5} width="100%" />
		</InputFilter>
	)
}
