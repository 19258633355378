import { type ReactElement } from 'react'
import { type GetOfferListQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { generateRoute } from '../../../../router/routes'
import { OffersPath } from '../../OffersRouter'
import { OrganizationCard } from '@shared/components/cards/OrganizationCard'
import { OfferCardActions } from './OfferCardActions'
import { OfferLocation } from './OfferLocation'
import { OfferDuration } from './OfferDuration'
import { Stack } from '@mui/material'
import { OfferCount } from './OfferCount'

interface Props {
	offer: GetOfferListQuery['getOfferList']['nodes'][0]
	isSelected?: boolean
	selectRow?: () => void
}

export const OfferCard = ({ offer }: Props): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<OrganizationCard
			avatarUrl={offer?.orgaRef?.logoUrl ?? ''}
			title={`${offer.label} · ${offer.orgaRef?.displayName ?? ''}`}
			to={generateRoute(OffersPath.matchings, { trackOfferId: offer.id })}
			linkLabel={t('offers:list.profilesButton')}
			actions={(isHovered) => (
				<OfferCardActions isHovered={isHovered} isArchived={offer.isArchived} offerId={offer.id} />
			)}
		>
			<OfferDuration
				sx={{ mt: 1 }}
				offerTypeName={offer.offerType.name ?? ''}
				contractDurationIds={offer.contractDurationIds}
			/>
			<OfferLocation location={offer.location.label} />
			<Stack direction="row" spacing={2}>
				<OfferCount count={offer.matchingCount} translationKey="offers:list.indicator.matching" />
				<OfferCount count={offer.matchingCandidatesCount} translationKey="offers:list.indicator.candidate" />
			</Stack>
		</OrganizationCard>
	)
}
