import { useParams } from 'react-router-dom'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import useUserBContext from '@shared/hooks/useUserBContext'
import { useGetInterviewDetailsQuery, useMeQuery } from '@gqlTypes'
import { MotivationForm } from '@domains/motivations/MotivationForm'
import { Box, Container, Stack } from '@mui/material'
import { useRole } from '@shared/hooks/useRole'
import { InterviewSignatures } from '../signature/InterviewSignatures'
import { InterviewForm } from './interviewEvaluation/InterviewForm'
import { InterviewSurvey } from './InterviewSurvey'
import { InterviewPdf } from './InterviewPdf'
import { type ReactElement, useRef } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'
import { InterviewInformation } from './InterviewInformation'

export const InterviewDetailPage = (): ReactElement | null => {
	const { interviewId } = useParams<{ interviewId: string }>()
	const { t } = useCustomTranslation()
	const userBContext = useUserBContext()
	const { isExternal } = useRole()
	const { data: userOrganizationData } = useMeQuery()
	const userOrganization = userOrganizationData?.me?.organization ?? null
	const hasAllMotivationQuestionsRequired = userOrganization?.hasAllMotivationQuestionsRequired ?? false
	const { hasPdfExport } = useOrganization()

	const { loading: isLoading, data } = useGetInterviewDetailsQuery({
		variables: { interviewId },
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first'
	})

	const name = data?.getInterview?.userIntegration?.user?.fullName
	useDocumentTitle(t('translation:candidates.interview.title', { name }))
	const tabBarContainer = useRef(null)
	if (isLoading || data === undefined) return <LocalLoader />

	const connectedUserId = data?.me?.id ?? ''
	const interview = data.getInterview
	if (interview == null) return null

	const userIntegration = interview.userIntegration
	const track = interview.track
	const isManager = userBContext.id !== interview.userId

	return (
		<Container maxWidth="xl" sx={{ marginBottom: 15 }}>
			<Box sx={{ position: 'sticky', top: 0, zIndex: 1, marginBottom: 2 }} ref={tabBarContainer} />
			<Stack spacing={3} mb={6}>
				<InterviewInformation interview={interview} connectedUserId={connectedUserId} />
				{!isExternal ? (
					<MotivationForm interview={interview} hasAllMotivationQuestionsRequired={hasAllMotivationQuestionsRequired} />
				) : null}
				<InterviewSurvey isShowMoreExpandedByDefault interview={interview} />
				<InterviewForm
					interview={interview}
					track={track}
					userIntegration={userIntegration}
					tabBarContainer={tabBarContainer}
				/>
				<InterviewSignatures
					isEditable={!interview.isArchived}
					isVisible={interview.stepsState.interviewSignature != null && interview.stepsState.interview === true}
					interviewId={interviewId}
					signatures={interview.signatures}
					assessorFullName={interview.assessorFullName}
					assessedFullName={interview.assessedFullName}
					assessedId={interview.userId ?? ''}
					assessorId={interview.juryId ?? ''}
					hasPdfExport={hasPdfExport}
				/>
				{!isExternal ? (
					<InterviewPdf
						interviewId={interviewId}
						pdfUrl={interview.pdfUrl}
						hasGenerateRights={!interview.isDraft && isManager}
						hasPdfExport={hasPdfExport}
					/>
				) : null}
			</Stack>
		</Container>
	)
}
