import { useOrganization } from '@domains/organization/useOrganization'
import { type ReactElement } from 'react'
import { ChipSelector } from '@ui/form/ChipSelector'
import { Box, Typography } from '@mui/material'
import { useWatch } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const OrgaSkillSection = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { orgaSkillsData } = useOrganization()
	const defaultValue = orgaSkillsData.map(({ id }) => id)
	const selectedSkillIds: string[] | undefined = useWatch({ name: 'skillIds', defaultValue })

	return (
		<Box>
			<Typography gutterBottom>
				<b>{t('modal:massFeedback.title_theme', { count: selectedSkillIds.length })}</b>
			</Typography>
			<ChipSelector name="skillIds" items={orgaSkillsData} minSelectableItem={1} />
		</Box>
	)
}
