import { type GetPositionRiskHistoryQuery } from '@gqlTypes'
import { Grid, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { RiskLabel } from '../RiskLabel'
import { joinWithPoint } from '#helpers/string.helper'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	entry: GetPositionRiskHistoryQuery['getUserPosition']['riskHistory'][0]
}

export const RiskEntry = ({ entry }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const title = joinWithPoint([
		t('risk:modal.history.entry.date', { date: entry.creationDateLabel }),
		t('risk:modal.history.entry.author', { author: entry.displayName })
	])
	return (
		<>
			<Grid item xs={3}>
				<RiskLabel risk={entry.risk} />
			</Grid>
			<Grid item xs={9}>
				<Stack direction="column" spacing={1}>
					<Typography>{title}</Typography>
					<Typography sx={{ color: 'grays.gray2', fontStyle: 'italic' }}>{entry.comment}</Typography>
				</Stack>
			</Grid>
		</>
	)
}
