import { Typography } from '@mui/material'
import { Slider } from '@ui/core/Slider'
import { useGetImageReturnResponseQuery } from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { makeStyles } from 'tss-react/mui'
import { type ReactElement } from 'react'

interface Props {
	imageReturnResponseId: string
}

export const ImageReturnDetailMessage = ({ imageReturnResponseId }: Props): ReactElement | null => {
	const { classes } = useStyle()
	const { t } = useCustomTranslation()

	const variables = { imageReturnResponseId }

	const { loading, data } = useGetImageReturnResponseQuery({ variables })
	if (loading) return <LocalLoader />
	if (data == null) return null
	const details = data.getImageReturnResponse.answersDetails

	if (details.length === 0 && !loading) {
		return <Typography>{t('discussion:message.imageReturnResponse.noDetails')}</Typography>
	}
	return (
		<>
			{details.map((detail) => {
				return (
					<Slider
						className={classes.slider}
						key={detail.id}
						min={0}
						max={5}
						readOnly
						value={detail.score}
						size={4}
						startLabel={detail.label}
					/>
				)
			})}
		</>
	)
}

const useStyle = makeStyles()(() => ({
	slider: {
		marginBottom: 10
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row-reverse'
	}
}))
