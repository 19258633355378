import { type ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type GetInterviewSoftSkillRestitutionQuery } from '@gqlTypes'
import { ChipList } from '@shared/components/ChipList'
import { addIdFromIndex } from '#helpers/array.helper'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import SpaIcon from '@mui/icons-material/Spa'

interface Props {
	topKeywordsCount: GetInterviewSoftSkillRestitutionQuery['getInterviewSoftSkillRestitution']['topKeywordsCount']
	bottomKeywordsCount: GetInterviewSoftSkillRestitutionQuery['getInterviewSoftSkillRestitution']['bottomKeywordsCount']
}

export const SoftSKillRestitutionKeywords = ({ topKeywordsCount, bottomKeywordsCount }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	if (topKeywordsCount.length === 0 && bottomKeywordsCount.length === 0) return null

	return (
		<Stack spacing={1}>
			<Typography color="primary" sx={{ fontWeight: 'bold', fontSize: 18, marginBottom: 1 }}>
				{t('sharedReturnTab:keywords.title')}
			</Typography>
			<Stack direction="row" spacing={1} sx={{ color: 'grey.500', fontStyle: 'italic' }}>
				<EmojiEventsIcon fontSize="small" />
				<Typography>{t('sharedReturnTab:keywords.top')}</Typography>
			</Stack>
			<ChipList withCount items={addIdFromIndex(topKeywordsCount)} />
			<Stack direction="row" spacing={1} sx={{ color: 'grey.500', fontStyle: 'italic' }}>
				<SpaIcon fontSize="small" />
				<Typography>{t('sharedReturnTab:keywords.bottom')}</Typography>
			</Stack>
			<ChipList withCount items={addIdFromIndex(bottomKeywordsCount)} />
		</Stack>
	)
}
