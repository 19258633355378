import { type ReactElement, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { Slider } from '@ui/core/Slider'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ImageReturnDetailMessage } from './ImageReturnDetailMessage'

interface Props {
	skillId: string
	globalScore: number
	globalComment: string
	imageReturnResponseId: string
}

export const ImageReturnMessage = ({
	skillId,
	globalScore,
	globalComment,
	imageReturnResponseId
}: Props): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useCustomTranslation()

	const skillName = t(`orgaSkill:orgaSkill.${skillId}.name`)
	const title = t('discussion:message.imageReturnResponse.title', { skillName })
	const isOpenLabel = isOpen ? 'seeLess' : 'seeMore'

	return (
		<div>
			<Typography style={{ height: 20, width: 350 }}>{title}</Typography>
			{globalComment !== '' && <Typography>{globalComment}</Typography>}
			<Slider min={0} max={5} readOnly value={globalScore} />
			<Button
				style={{ marginBottom: 10 }}
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				{t(`discussion:message.imageReturnResponse.openButton.${isOpenLabel}`)}
			</Button>
			{isOpen && <ImageReturnDetailMessage imageReturnResponseId={imageReturnResponseId} />}
		</div>
	)
}
