import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	organizationType: string
}

export const OrganizationSiret = ({ organizationType }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const message = t('organizationCreation:requiredField')

	return (
		<InputTextFieldForm
			name="organizationSiret"
			label={t(`organizationCreation:organization.${organizationType}.field.siret`)}
			rules={{
				required: { value: true, message },
				pattern: {
					value: /^[0-9]{9}$/,
					message: t('organizationCreation:error.siret')
				}
			}}
		/>
	)
}
