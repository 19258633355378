import { useCustomTranslation } from '#translations/useCustomTranslation'
import { NewInterviewDrawer } from '@domains/interviews/create/NewInterviewDrawer'
import { useCreateJobMatchingInterviewMutation, useMeQuery } from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { ButtonWithTooltip } from '@ui/buttons/ButtonWithTooltip'
import { useState, type ReactElement } from 'react'

interface Props {
	jobSearchId: string
	trackOfferId: string
}

export const JobSearchInterview = ({ jobSearchId, trackOfferId }: Props): ReactElement | null => {
	const { data: meData, loading: isMeLoading } = useMeQuery()
	const [createInterview, { loading: isCreatingInterview }] = useCreateJobMatchingInterviewMutation()

	const { t } = useCustomTranslation()
	const [isOpen, setIsOpen] = useState<boolean>(false)

	if (isMeLoading) return <LocalLoader />
	if (meData == null) return null

	return (
		<>
			<ButtonWithTooltip
				variant="contained"
				color="secondary"
				onClick={() => {
					setIsOpen(true)
				}}
				tooltipLabel={t('offers:jobMatching.browser.interview.create.tooltip.enabled')}
			>
				{t('offers:jobMatching.browser.interview.create.label')}
			</ButtonWithTooltip>
			<NewInterviewDrawer
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				assessorId={meData.me?.id ?? ''}
				createInterview={createInterview}
				loading={isCreatingInterview}
				jobSearchId={jobSearchId}
				trackOfferId={trackOfferId}
			/>
		</>
	)
}
