import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Container, Stack, Typography } from '@mui/material'
import { CardWithLogo } from '@shared/components/CardWithLogo'

export const OnlyMobile = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<Stack sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
			<Container maxWidth="sm" sx={{ mb: 5 }}>
				<CardWithLogo>
					<Stack spacing={3} alignItems="center">
						<Typography variant="h5">{t('login.onlyMobile.title')}</Typography>
						<Typography>
							{t('login.onlyMobile.message')} <a href="https://emage-me.com/mobile">{t('login.accountNotFound.app')}</a>
							.
						</Typography>
						<img width={250} alt="app mobile" src="/assets/img/mobile-qr-code.png" />
					</Stack>
				</CardWithLogo>
			</Container>
		</Stack>
	)
}
