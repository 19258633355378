import { SelectorUpdater } from '../SelectorUpdater'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetManagersQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	onValidate: (field: string, newValue: string | boolean) => Promise<void>
}

export const ManagerUpdateSection = ({ onValidate }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers.map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName })) ?? []

	return (
		<SelectorUpdater onValidate={onValidate} items={managers} field="manager" title={t('modal:massUpdate.manager')} />
	)
}
