import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetInterviewDurationsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'

export const DurationField = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { loading, data } = useGetInterviewDurationsQuery()
	const interviewDuration = data?.getInterviewDurations ?? []
	const items = interviewDuration.map(({ id, label }) => ({ value: id, label }))

	if (interviewDuration.length === 0) return null
	return (
		<AutocompleteSelect
			name="durationId"
			items={items}
			label={t('drawer:interview.interviewDuration')}
			loading={loading}
			inputProps={{ size: 'small' }}
			orderItems={false}
		/>
	)
}
