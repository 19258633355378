import { type ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { GetUserOfferJobMatchingStatusCountDocument, JobMatchingStatusId, useUpdateJobStatusMutation } from '@gqlTypes'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'

interface Props {
	setIsOpen: (state: boolean) => void
	jobMatchingId: string
	trackOfferId: string
	setIsSelected: (state: boolean) => void
}

export const ToConfirmeContent = ({
	setIsOpen,
	jobMatchingId,
	setIsSelected,
	trackOfferId
}: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const translationKey = 'offers:jobMatching.browser.popup.selected.toValidate'
	const { onError } = useGqlSnackBar(translationKey)
	const [update] = useUpdateJobStatusMutation({
		onError,
		refetchQueries: [{ query: GetUserOfferJobMatchingStatusCountDocument, variables: { trackOfferId } }]
	})

	const handleConfirme = (): void => {
		void update({ variables: { jobMatchingId, statusId: JobMatchingStatusId.Selected } })
		setIsSelected(true)
	}

	return (
		<>
			<img src="/assets/img/selected.png" style={{ objectFit: 'contain', height: 350 }} />
			<Typography fontWeight="bold" fontSize={30} color={'secondary'}>
				{t(`${translationKey}.title`)}
			</Typography>
			<Typography fontSize={20}>{t(`${translationKey}.subtitle`)}</Typography>
			<Stack direction="row" justifyContent="center" gap={2}>
				<Button
					variant="contained"
					onClick={() => {
						setIsOpen(false)
					}}
				>
					{t(`${translationKey}.cancel`)}
				</Button>
				<Button variant="contained" color={'secondary'} onClick={handleConfirme}>
					{t(`${translationKey}.confirme`)}
				</Button>
			</Stack>
		</>
	)
}
