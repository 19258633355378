import Typography from '@mui/material/Typography'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { DataTableHeader } from './DataTableHeader'
import { DataTableRow } from './DataTableRow'
import { type ReactElement, type ReactNode } from 'react'
import { type Cell } from '@domains/communityMembers/list/useCommunityMembersColumns'
import { useTheme } from '@mui/material'

interface Props {
	onSelectAllRows: (isAllRowSelected: boolean) => void
	isAllRowsSelected: boolean
	columns: Cell[]
	rowGetter: (currentIndex: number) => unknown
	rowCount: number
	selectedRows: number[]
	onRowSelectionChange: (newSelectedRows: number[]) => void
	selectable: boolean
	emptyStateLabel: ReactNode
}

export const DataTable = ({
	onSelectAllRows,
	isAllRowsSelected,
	columns,
	rowGetter,
	rowCount,
	selectedRows,
	onRowSelectionChange,
	selectable,
	emptyStateLabel
}: Props): ReactElement => {
	const theme = useTheme()
	const { t } = useCustomTranslation()

	const displayedColumns = columns.filter((column) => column.isDisplay == null || column.isDisplay)

	function setSelectionForRow(index: number): void {
		if (onRowSelectionChange == null) return

		let newSelectedRows = []

		if (selectedRows.includes(index)) {
			newSelectedRows = selectedRows.filter((el) => el !== index)
		} else {
			newSelectedRows = [...selectedRows, index]
		}
		const isAllRowSelected = rowCount === newSelectedRows.length
		onRowSelectionChange(newSelectedRows)
		onSelectAllRows(isAllRowSelected)
	}

	function setSelectAll(isSelectAll: boolean): void {
		if (onRowSelectionChange == null) return

		if (isSelectAll) {
			onSelectAllRows(true)
			const allSelection = new Array(rowCount).fill(0).map((_, i) => i)
			onRowSelectionChange(allSelection)
		} else {
			onSelectAllRows(false)
			onRowSelectionChange([])
		}
	}

	const totalRows = rowCount

	const rowsEls = new Array(totalRows).fill(null).map((_, i) => {
		const currentIndex = i
		return (
			<DataTableRow
				key={currentIndex}
				columns={displayedColumns}
				selectable={selectable}
				rowData={rowGetter(currentIndex)}
				rowIndex={currentIndex}
				selected={selectedRows.includes(currentIndex)}
				onSelectionChange={(isSelected: boolean) => {
					setSelectionForRow(currentIndex)
				}}
			/>
		)
	})

	return (
		<div>
			{totalRows > 0 && (
				<>
					<TableContainer>
						<Table>
							<DataTableHeader
								columns={displayedColumns}
								selectable={selectable}
								onSelectAll={(isAllSelected: boolean) => {
									setSelectAll(isAllSelected)
								}}
								allSelected={isAllRowsSelected}
							/>
							<TableBody>{rowsEls}</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			{totalRows < 1 && (
				<div
					style={{
						height: 400,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						color: theme.palette.grays.gray2
					}}
				>
					<div className="icon">
						<FormatListBulletedIcon />
					</div>
					<Typography>{emptyStateLabel ?? t('lists:dataGrid.empty')}</Typography>
				</div>
			)}
		</div>
	)
}
