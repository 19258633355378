import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	handleClose: () => void
}

export const FeedbackComment = ({ handleClose }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<>
			<DialogTitle color="secondary">{t('feedbackRequest:interview.mailRequest.title')}</DialogTitle>
			<DialogContent sx={{ width: '100%' }}>
				<InputTextFieldForm
					fullWidth
					name="upgrade"
					label={t('feedbackRequest:interview.mailRequest.increase')}
					size="medium"
					multiline
					rows={4}
					sx={{ marginTop: 4 }}
				/>
			</DialogContent>
			<DialogActions>
				<LoadingButton onClick={handleClose} color="secondary" variant="contained">
					{t('feedbackRequest:interview.validate')}
				</LoadingButton>
			</DialogActions>
		</>
	)
}
