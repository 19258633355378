import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { useMeQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const UserFunctionSelect = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data, loading } = useMeQuery()
	const { errors } = useFormContext()
	const items = (data?.me?.organization?.matchingRestrictions?.userFunctions ?? []).map((item) => ({
		value: item.id ?? '',
		label: item.name ?? ''
	}))

	const name = 'userFunction'
	const hasError = errors[name] != null
	const errorMessage = errors[name]?.message ?? ''
	if (items.length <= 1) return null

	return (
		<TableLayoutRow translationKey="offers:details.main.function">
			<AutocompleteSelect
				name={name}
				items={items}
				loading={loading}
				rules={{ required: t('form:errors.required') }}
				hasError={hasError}
				errorMessage={errorMessage}
			/>
		</TableLayoutRow>
	)
}
