import { Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { MemberSelector } from './member/MemberSelector'
import { FeedbackRightColumn } from './FeedbackRightColumn'
import { useSendFeedbackRequestsMutation } from '@gqlTypes'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { FeedbackRequestPath } from './FeedbackRequest.router'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { LoadingButton } from '@mui/lab'

interface Submit {
	userIds: string[]
	message: string
	dueDate?: string
	softskillIds: string[]
}

export const FeedbackRequestForm = (): ReactElement => {
	const history = useHistory()
	const { t } = useCustomTranslation()
	const { fullrequest = '', interviewId = '' } = useParams<{ fullrequest: string; interviewId: string }>()

	const defaultValues = {
		userIds: [],
		dueDate: null
	}

	const { onError } = useGqlSnackBar('offers:details.snackbar.create')

	const formMethods = useForm({ mode: 'onChange', defaultValues })
	const { handleSubmit, getValues } = formMethods

	const [sendFeedbackRequests, { loading: isSaving }] = useSendFeedbackRequestsMutation({ onError })
	const onSubmit = async (data: Submit): Promise<void> => {
		await sendFeedbackRequests({
			variables: {
				feedbackRequestInput: {
					...data,
					interviewId,
					isFullRequest: fullrequest !== ''
				}
			}
		})
		history.push(generatePath(FeedbackRequestPath.thanks, { feedbackCount: getValues('userIds').length }))
	}

	return (
		<Container maxWidth="xl" sx={{ pt: 4 }}>
			<Stack direction="row" alignItems="center" mb={2}>
				<IconButton
					sx={{ color: 'primary.main' }}
					onClick={() => {
						history.goBack()
					}}
				>
					<ArrowBackIosIcon />
				</IconButton>

				<Typography sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: 25 }}>
					{t('feedbackRequest:form.title')}
				</Typography>
			</Stack>

			<FormProvider {...formMethods}>
				<form>
					<Grid container columnSpacing={10} sx={{ mt: 0 }}>
						<Grid item xs={12} md={6}>
							<MemberSelector />
						</Grid>
						<Grid item xs={12} md={6}>
							<FeedbackRightColumn />
							<LoadingButton
								loading={isSaving}
								sx={{ mt: 4 }}
								variant="contained"
								color="secondary"
								onClick={handleSubmit(onSubmit)}
							>
								{t('feedbackRequest:form.send')}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Container>
	)
}
