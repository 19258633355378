import { type ReactElement } from 'react'
import { Alert } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	isMissing: boolean
}

export const MandatoryStepAlert = ({ isMissing }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	if (!isMissing) return null

	return <Alert severity="warning">{t('interview:form.mandatoryStep')}</Alert>
}
