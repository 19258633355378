import { Stack, TextField, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { TemplateSelector } from './template/TemplateSelector'
import { SkillSelector } from './softskill/SkillSelector'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Controller } from 'react-hook-form'
import { DateTime } from 'luxon'

export const FeedbackRightColumn = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<>
			<Typography sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: 20 }}>
				{t('feedbackRequest:form.feedback.title')}
			</Typography>
			<Stack spacing={3} mt={2}>
				<TemplateSelector />
				<SkillSelector />
				<Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
					{t('feedbackRequest:form.feedback.date.title')}
				</Typography>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<Controller
							name="dueDate"
							render={({ onChange, value }: { onChange: (newValue: string | null) => void; value: string | null }) => {
								const luxonValue = value != null ? DateTime.fromISO(value) : null
								return (
									<DatePicker
										label={t('feedbackRequest:form.feedback.date.placeholder')}
										inputFormat="dd/MM/yyyy"
										value={luxonValue}
										onChange={(newValue) => {
											onChange(newValue != null ? newValue.toISO() : null)
										}}
										renderInput={(params) => <TextField {...params} variant="outlined" />}
									/>
								)
							}}
						/>
					</LocalizationProvider>
				</Stack>
			</Stack>
		</>
	)
}
