import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircle'
import UncheckIcon from '@mui/icons-material/RadioButtonUnchecked'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	textKey: string
	isValid: boolean
}

export const PasswordRule = ({ textKey, isValid }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<ListItem>
			<ListItemIcon>{isValid ? <CheckIcon color="secondary" /> : <UncheckIcon />}</ListItemIcon>
			<ListItemText>{t(textKey)}</ListItemText>
		</ListItem>
	)
}
