import { useGetEducationLevelsQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'

export const EducationLevelFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useGetEducationLevelsQuery()

	if (isLoading || data?.getEducationLevels == null) return null
	const educationLevels = data.getEducationLevels.map(({ id: value, label }) => ({ value, label }))

	return (
		<InputFilter keyName="educationLevelIds" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter options={educationLevels} label={t('lists:filter.educationLevel.label')} />
		</InputFilter>
	)
}
