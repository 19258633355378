import { useGetOrganizationFeedbackResponsesQuery } from '@gqlTypes'
import { Stack } from '@mui/material'
import { type ReactElement, useEffect, useState } from 'react'
import { OrgaReturn } from '../tab/OrgaReturn'
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	timelineItemClasses,
	TimelineSeparator
} from '@mui/lab'
import { useDate } from '@ui/useDate'
import { EmptyList } from '@ui/list/EmptyList'
import { useTranslation } from 'react-i18next'

interface Props {
	userId: string
}

export const OrganizationFeedbackResponses = ({ userId }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { data } = useGetOrganizationFeedbackResponsesQuery({ variables: { userId } })
	const [requestId, setRequestId] = useState('')
	const { MedDate } = useDate()
	const responses = data?.getOrganizationFeedbackResponses ?? []
	useEffect(() => {
		if (data != null) setRequestId(responses?.[0]?.requestId ?? '')
	}, [data])

	if (responses.length === 0) return <EmptyList label={t('translation:emptyImageText')} />

	return (
		<Stack direction="row">
			<Timeline
				sx={{
					width: 250,
					[`& .${timelineItemClasses.root}:before`]: {
						flex: 0,
						padding: 0
					}
				}}
			>
				{responses.map((response, index) => {
					const isSelected = requestId === response.requestId
					return (
						<TimelineItem
							key={response.id}
							onClick={() => {
								setRequestId(response.requestId)
							}}
							sx={{ opacity: isSelected ? 1 : 0.5, cursor: 'pointer' }}
						>
							<TimelineSeparator>
								<TimelineDot color="primary" />
								{index < responses.length - 1 ? <TimelineConnector color="primary" /> : null}
							</TimelineSeparator>
							<TimelineContent color="primary">
								<b>{MedDate(response.creationDate)}</b>
								<br />
								{response.label}
							</TimelineContent>
						</TimelineItem>
					)
				})}
			</Timeline>
			{requestId !== '' ? <OrgaReturn filterKey="requestId" value={requestId} /> : null}
		</Stack>
	)
}
