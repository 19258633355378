import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ChipSelector } from '@ui/form/ChipSelector'
import { useGetLanguagesQuery } from '@gqlTypes'
import { ColumnLayout } from '@shared/components/layout/ColumnLayout'

export const LanguagesSelector = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useGetLanguagesQuery()

	return (
		<ColumnLayout
			title={t('offers:details.expectedSkills.languages.title')}
			tooltip={t('offers:details.expectedSkills.languages.tooltip')}
		>
			<ChipSelector name="requiredLanguageIds" items={data?.getLanguages ?? []} isLoading={isLoading} />
		</ColumnLayout>
	)
}
