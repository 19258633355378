import { type ReactElement } from 'react'
import { Stack, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

interface Props {
	label: string
	buttonLabel?: string
	imagePath?: string
	onClick?: React.MouseEventHandler<HTMLElement>
	to?: string
}

export const EmptyList = ({
	label,
	buttonLabel = '',
	imagePath = '/assets/img/noFeedback.png',
	onClick,
	to
}: Props): ReactElement => {
	const hasButton = to != null || onClick != null
	return (
		<Stack gap={4} alignItems="center" mt={5}>
			<Typography color="primary" style={{ fontWeight: 'bold', fontSize: 18 }}>
				{label}
			</Typography>
			<img src={imagePath} />
			{hasButton ? (
				<Button component={Link} to={to} onClick={onClick} color="secondary" variant="contained">
					{buttonLabel}
				</Button>
			) : null}
		</Stack>
	)
}
