import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Container, Stack, Typography } from '@mui/material'
import { CardWithLogo } from '@shared/components/CardWithLogo'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'

export const OrganizationCreationSent = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<Container maxWidth="sm" sx={{ mt: 10 }}>
			<CardWithLogo>
				<Stack spacing={2}>
					<Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
						{t('organizationCreation:sent.title')}
					</Typography>
					<Stack direction="row" spacing={1}>
						<MarkEmailReadIcon sx={{ color: 'secondary.main' }} />
						<Typography sx={{ fontWeight: 'bold' }}>{t('organizationCreation:sent.mailSent')}</Typography>
					</Stack>
					<Typography>{t('organizationCreation:sent.message')}</Typography>
				</Stack>
			</CardWithLogo>
		</Container>
	)
}
