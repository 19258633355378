import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ChipSelector } from '@ui/form/ChipSelector'
import { useWatch } from 'react-hook-form'
import { useGetUserFunctionQuery, useMeQuery } from '@gqlTypes'
import { ColumnLayout } from '@shared/components/layout/ColumnLayout'
import { ConditionalWrapper } from '@shared/components/ConditionalWrapper'

export const TopHardSkill = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data: dataMe, loading: isMeLoading } = useMeQuery()

	const formUserFunctionId = useWatch<string>({ name: 'userFunction' }) ?? ''
	const defaultUserFunctionIds = (dataMe?.me?.organization?.matchingRestrictions?.userFunctions ?? []).map(
		({ id }) => id
	)
	const userFunctionId = defaultUserFunctionIds.length === 1 ? defaultUserFunctionIds[0] : formUserFunctionId

	const { data, loading: isLoading } = useGetUserFunctionQuery({
		variables: { userFunctionId }
	})
	const macroHardSkills = data?.getUserFunction?.macroHardSkills ?? []
	const microHardSkills = data?.getUserFunction?.microHardSkills ?? []

	return (
		<>
			<ColumnLayout title={t('offers:details.expectedSkills.macroHardSkills.title')}>
				<ChipSelector
					name="topMacroHardSkillIds"
					items={macroHardSkills}
					isLoading={isLoading || isMeLoading}
					minSelectableItem={3}
					isSortedAlphabetically
				/>
			</ColumnLayout>
			<ConditionalWrapper
				condition={microHardSkills.length > 0}
				wrapper={(children) => (
					<ColumnLayout title={t('offers:details.expectedSkills.microHardSkills.title')}>{children}</ColumnLayout>
				)}
			>
				<ChipSelector
					name="topMicroHardSkillIds"
					items={microHardSkills}
					isLoading={isLoading || isMeLoading}
					isSortedAlphabetically
				/>
			</ConditionalWrapper>
		</>
	)
}
