import { useCustomTranslation } from '#translations/useCustomTranslation'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { useMeQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

export const CommunityLabelsFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })

	if (loading || data == null || data?.me.organization.communityLabels.length === 0) return null

	const options = data?.me.organization.communityLabels.map((label) => ({
		value: label,
		label
	}))

	return (
		<InputFilter keyName="communityLabels" operation={filterOperation.CONTAIN_ANY}>
			<AutoCompleteFilter
				options={options}
				label={t('lists:filter.communityLabels')}
				multiple
				limitTags={5}
				width="100%"
			/>
		</InputFilter>
	)
}
