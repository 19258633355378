import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Typography } from '@mui/material'
import { CampaignSelector } from '@domains/campaigns/CampaignSelector'

export const AutoFeedbackRequestForm = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<>
			<CampaignSelector />
			<Typography>{t('modal:massFeedback.mode.auto.text')}</Typography>
		</>
	)
}
