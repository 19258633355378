import { useCustomTranslation } from '#translations/useCustomTranslation'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { Grid, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { InterviewSignatureDate } from './InterviewSignatureDate'
import { InterviewSignatureButton } from './InterviewSignatureButton '

interface Props {
	title: string
	isSigned: boolean
	fullName: string
	signatureDate: string
	isSaving: boolean
	signInterview: () => void
	isSignable: boolean
}

export const InterviewSignature = ({
	title,
	isSigned,
	fullName,
	signatureDate,
	isSaving,
	signInterview,
	isSignable
}: Props): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<>
			<Grid item xs={12} sm={4}>
				<CardLayout title={title}>
					<InfoCardContent>
						{isSigned ? (
							<InterviewSignatureDate name={fullName} date={signatureDate} />
						) : isSignable ? (
							<InterviewSignatureButton isSaving={isSaving} signInterview={signInterview} />
						) : (
							<Typography style={{ textAlign: 'center' }}>{t('interview:signatures.empty')}</Typography>
						)}
					</InfoCardContent>
				</CardLayout>
			</Grid>
		</>
	)
}
