import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { CommentSection } from '../sections/CommentSection'
import { type IntegrationCategory, type TrackType, useSendUserIntegrationsMessageMutation } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { searchQuery } from '@features/lists/components/Search'
import { type Dispatch, type SetStateAction, type ReactElement } from 'react'

interface Props {
	rowCount: number
	trackType: TrackType
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	userIntegrationsIds: string[]
	integrationCategory: IntegrationCategory
}

export const MassMessagesModal = ({
	rowCount,
	trackType,
	isOpen,
	setIsOpen,
	userIntegrationsIds,
	integrationCategory
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { filters } = useFilters()
	const count = userIntegrationsIds.length > 0 ? userIntegrationsIds.length : rowCount
	const [sendUserIntegrationsMessage] = useSendUserIntegrationsMessageMutation()

	const sendMessages = async (_: unknown, message: string): Promise<void> => {
		const variables = {
			userIntegrationsIds,
			trackType,
			filters,
			integrationCategory,
			searchQuery: searchQuery(),
			message
		}
		await sendUserIntegrationsMessage({ variables })
		setIsOpen(false)
	}

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
		>
			<DialogTitle>{t('modal:massMessages.title', { count })}</DialogTitle>
			<Divider />
			<CommentSection onValidate={sendMessages} userIds={userIntegrationsIds} label={t('modal:massMessages.message')} />
		</Dialog>
	)
}
