import PropTypes from 'prop-types'
import StarIcon from '@mui/icons-material/Star'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import { InputLabel, Typography } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import Rating from '@mui/lab/Rating'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import Stack from '@mui/material/Stack'

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'inline-flex',
		overflow: 'hidden',
		flexDirection: ({ orientation }) => (orientation === 'horizontal' ? 'row' : 'column'),
		'&:hover $unratedText': {
			display: 'none'
		}
	},
	starContainer: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',

		"& input[type='radio']": {
			display: 'none',
			opacity: 0
		}
	},
	starLeft: {
		display: 'inline-block',
		width: '0.5em',
		overflow: 'hidden'
	},
	starRight: {
		display: 'inline-block',
		width: '0.5em',
		overflow: 'hidden',
		'& > *': {
			transform: 'translateX(-50%)'
		}
	},
	unrated: {
		'&:not(:hover)': {
			color: theme.palette.grays.gray2,
			opacity: 0.5
		}
	},
	unratedText: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '0.8em',
		zIndex: 2,
		background: 'transparent',
		border: 'none'
	},
	label: {
		alignSelf: 'center',
		marginRight: '0.5em',
		paddingRight: '0.5em',
		fontStyle: 'italic',
		whiteSpace: 'nowrap'
	},
	rating: {},
	clear: {
		width: '1em',
		color: theme.palette.grays.gray2,
		marginLeft: '0.2em',
		fontSize: '1.2em',
		padding: '0',
		paddingTop: '0.15em',
		background: 'transparent',
		border: 'none',

		'&.hidden': {
			opacity: 0
		}
	}
}))

export function StarRatingInput(props) {
	const { value, name, label, arrayShape, id: propsId, onChange, readOnly, unratedLabel, orientation } = props
	const { classes } = useStyles(props)
	const { t } = useCustomTranslation()

	function setValue(newValue) {
		if (arrayShape) {
			const arrayValue = [0]
			for (let i = 0; i < newValue; i++) {
				arrayValue.push(i + 1)
			}
			newValue = arrayValue
		}

		if (onChange && !readOnly) onChange(newValue)
	}

	const id = propsId || name
	const isUnrated = (!value && value !== 0) || value < 0
	const flexDirection = orientation === 'horizontal' ? 'row' : 'column'

	return (
		<Stack className={classes.root} sx={{ flexDirection }}>
			{label && <InputLabel className={classes.label}>{label}</InputLabel>}
			<div className={classes.starContainer}>
				<Rating
					className={clsx(classes.rating, isUnrated ? classes.unrated : null)}
					name={name}
					readOnly={props.readOnly}
					onChange={(_, newVal) => setValue(newVal)}
					value={value >= 0 ? Math.round(value) : null}
					icon={<StarIcon fontSize="inherit" />}
					emptyIcon={<StarOutlineOutlinedIcon fontSize="inherit" />}
				/>

				{!props.readOnly && !props.disableReset && (
					<button
						type="button"
						className={classes.clear + (isUnrated ? ' hidden' : '')}
						title={t('translation:rating.reset')}
						onClick={() => setValue('empty')}
					>
						<ClearIcon fontSize="inherit" />
					</button>
				)}

				{isUnrated && (
					<button type="button" htmlFor={`${id}-0`} className={classes.unratedText} onClick={() => setValue(1)}>
						<Typography variant="caption">{unratedLabel || t('translation:rating.unrated')}</Typography>
					</button>
				)}
			</div>
		</Stack>
	)
}

StarRatingInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	name: PropTypes.string,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.node,
	unratedLabel: PropTypes.node,
	disableReset: PropTypes.bool,
	forceInteger: PropTypes.bool,
	arrayShape: PropTypes.bool,
	orientation: PropTypes.oneOf(['horizontal', 'vertical'])
}

StarRatingInput.defaultProps = {
	value: null,
	name: 'rating',
	readOnly: false,
	orientation: 'vertical'
}

export default StarRatingInput
