import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Control, Controller, type FieldError } from 'react-hook-form'
import { PhoneInput } from '@ui/phone/PhoneInput'

interface Props {
	control: Control
	defaultValue?: string
	name: string
	required?: boolean
	error?: FieldError
}

export default function PhoneField({ control, defaultValue, name, required, error }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const translationKey = `organizationUsers:details.formFields.${name}`
	const errorMessage = error?.type === 'required' ? t('form:errors.required') : ''

	const title = t(`${translationKey}.title`)
	const tooltip = t(`${translationKey}.tooltip`)

	return (
		<TableLayoutRow title={title} tooltip={tooltip}>
			<Controller
				control={control}
				rules={{ required }}
				name={name}
				defaultValue={defaultValue}
				render={({ onChange, value }) => {
					return (
						<PhoneInput
							label=""
							variant="outlined"
							size="small"
							onChange={onChange}
							value={value}
							error={error?.type === 'required'}
							helperText={errorMessage}
						/>
					)
				}}
			/>
		</TableLayoutRow>
	)
}
