import { useCustomTranslation } from '#translations/useCustomTranslation'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, { type AutoCompleteFilterProps } from './AutoCompleteFilter'
import { useGetRisksQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

export default function RiskFilter(props: AutoCompleteFilterProps): ReactElement | null {
	const { t } = useCustomTranslation()
	const { loading, data } = useGetRisksQuery()
	if (loading || data == null) return null

	const options = data?.getRisks.map(({ id, label }) => ({
		value: id,
		label
	}))

	return (
		<InputFilter keyName="riskId" operation={filterOperation.IN_ARRAY}>
			<AutoCompleteFilter
				{...props}
				options={options}
				label={t('lists:filter.risk')}
				multiple
				limitTags={5}
				width="100%"
			/>
		</InputFilter>
	)
}
