import { InfoCardContent } from '@ui/core/InfoCardContent'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import CardLayout from '@ui/core/CardLayout'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'
import { type DecryptedMessage } from '@domains/discussions/useDiscussionContent'

interface Props {
	messages: DecryptedMessage[]
}

export const MessageInfoCard = ({ messages }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	if (messages.length === 0) return null

	return (
		<CardLayout direction="row">
			<InfoCardContent>
				<Typography>{t('translation:discussionDetailPage.exchanged', { count: messages.length })}</Typography>
			</InfoCardContent>
			<InfoCardContent>
				<Typography>
					{t('translation:discussionDetailPage.first', {
						date: moment(messages[0].createdAt).format('DD/MM/yyyy')
					})}
				</Typography>
			</InfoCardContent>
			<InfoCardContent>
				<Typography>
					{t('translation:discussionDetailPage.last', {
						date: moment(messages[messages.length - 1].createdAt).format('DD/MM/yyyy')
					})}
				</Typography>
			</InfoCardContent>
		</CardLayout>
	)
}
