import { Controller } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { TextField } from '@mui/material'
import { DesktopTimePicker } from '@mui/x-date-pickers'
import { type ReactElement } from 'react'

export const StartTimeField = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<Controller
			name="startTime"
			render={({ onChange, value }) => {
				return (
					<DesktopTimePicker
						renderInput={(params) => <TextField size="small" {...params} />}
						value={value}
						onChange={onChange}
						label={t('drawer:interview.interviewTime')}
					/>
				)
			}}
		/>
	)
}
