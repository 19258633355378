import { type LegacyRef, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SkillGroupSelector from './SkillGroupSelector'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useSelectableSoftskillsLazyQuery, type SelectableSkillItem } from '@gqlTypes'
import { makeStyles } from 'tss-react/mui'
import { ControlledMultiSelectCard } from '../cards/ControlledMultiSelectCard'
import { SoftskillItem } from '../cards/SoftskillItem'
import { ErrorHandler } from '#helpers/error.helper'

interface Props {
	selectedSkills: SelectableSkillItem[]
	fieldName: string
	register: () => LegacyRef<HTMLInputElement> | undefined
}

const indexBydId = (array: SelectableSkillItem[]): Record<string, SelectableSkillItem> => {
	return array.reduce((previousValue, currentValue) => {
		return { ...previousValue, [currentValue.id]: currentValue }
	}, {})
}

export default function SkillSelector({ register, fieldName, selectedSkills }: Props): JSX.Element {
	const [getSelectableSoftSkill, { loading: isLoading, data }] = useSelectableSoftskillsLazyQuery()
	const { t } = useCustomTranslation()
	const title = t('tracks:details.cards.softskills.title')
	const { classes } = useStyles()
	const [open, setOpen] = useState(false)

	const skillGroups = data?.selectableSoftskills?.softSkillCategories ?? []
	const softSkillItems = data?.selectableSoftskills?.softSkillItems ?? []

	const [itemsState, setItemsState] = useState(indexBydId(selectedSkills))

	const skills: Record<string, SelectableSkillItem> = {}
	softSkillItems.forEach((skill) => {
		const { id, label, description, order } = skill
		skills[skill.id] = { id, label, description, order, isSelected: false, mandatory: false, orgaOnly: false }
	})

	const setIsSelected = (id: string, isSelected: boolean): void => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		setItemsState(({ [id]: value, ...otherItems }) => {
			return isSelected ? { ...otherItems, [id]: { ...skills[id], isSelected } } : otherItems
		})
	}

	const toggleIsMandatory = (id: string): void => {
		setItemsState(({ [id]: value, ...otherItems }) => ({
			...otherItems,
			[id]: { ...value, mandatory: !value.mandatory }
		}))
	}

	const toggleIsOrgaOnly = (id: string): void => {
		setItemsState(({ [id]: value, ...otherItems }) => ({
			...otherItems,
			[id]: { ...value, orgaOnly: !value.orgaOnly }
		}))
	}

	const handleOpen = (): void => {
		;(async () => {
			setOpen(true)
			await getSelectableSoftSkill()
		})().catch(ErrorHandler)
	}
	const handleClose = (): void => {
		setOpen(false)
	}
	return (
		<>
			<ControlledMultiSelectCard
				title={title}
				fieldName={fieldName}
				itemsState={itemsState}
				register={register}
				setIsSelected={setIsSelected}
				setItemsState={setItemsState}
				getValue={({ id, mandatory, orgaOnly }) => {
					return JSON.stringify({ id, mandatory, orgaOnly, expectedScore: 3 })
				}}
				renderItem={({ id, mandatory, orgaOnly }) => {
					return (
						<SoftskillItem
							id={id}
							mandatory={mandatory}
							orgaOnly={orgaOnly}
							onToggleIsMandatory={toggleIsMandatory}
							onToggleIsOrgaOnly={toggleIsOrgaOnly}
						/>
					)
				}}
			>
				<Button className={classes.button} variant="contained" color="primary" onClick={handleOpen}>
					{t('tracks:details.button.add')}
				</Button>
			</ControlledMultiSelectCard>

			<Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
				<DialogTitle>
					<Typography variant="h6">Sélectionner les compétences attendues</Typography>
					<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent className={classes.container}>
					<LocalLoader load={isLoading} withPadding>
						{skillGroups.map((skillGroup, index) => (
							<div key={`${index}-skillGroup`} className={classes.group}>
								<SkillGroupSelector
									label={skillGroup.label}
									color={skillGroup.color}
									skills={skillGroup.softSkillIds.map((id) => skills[id])}
									setIsSelected={setIsSelected}
									selectedItem={itemsState}
								/>
							</div>
						))}
					</LocalLoader>
				</DialogContent>
			</Dialog>
		</>
	)
}

const useStyles = makeStyles()((theme) => ({
	button: {
		marginTop: 20
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	group: {
		marginBottom: 30,
		width: 300
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	loader: {
		padding: 50
	}
}))
