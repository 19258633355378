import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const MinimumVersion = (): ReactElement => {
	const { t } = useCustomTranslation()
	const reload = (): void => {
		location.reload()
	}

	return (
		<Box sx={{ position: 'absolute', zIndex: 21, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0 }}>
			<Alert
				action={
					<Button color="inherit" size="small" onClick={reload}>
						{t('minimumVersion:updateApplication')}
					</Button>
				}
				severity="info"
			>
				{t('minimumVersion:requiredNewVersion')}
			</Alert>
		</Box>
	)
}
