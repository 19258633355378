import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetAssessorsQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

export const AssessorField = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = data?.getAssessors ?? []
	const items = assessors.map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))
	const rules = { required: t('drawer:interview.required') }

	return <AutocompleteSelect rules={rules} name="juryId" items={items} label={t('drawer:interview.assessor')} />
}
