import { Stack, type StackProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import MDIIcon from '@ui/icon/MDIIcon'

interface Props extends StackProps {
	offerTypeName: string
	contractDurationIds: string[]
}

export const OfferDuration = ({ offerTypeName, contractDurationIds, ...stackProps }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const durations = contractDurationIds
		.map((durationId) => t(`offers:details.main.duration.values.${durationId}`))
		.join(' • ')

	return (
		<Stack direction="row" gap={1} alignItems="center" {...stackProps}>
			<MDIIcon sx={{ color: 'grays.gray3' }} name="town-hall" size={18} />
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }}>
				{offerTypeName} {durations}
			</Typography>
		</Stack>
	)
}
