import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetUserFunctionLazyQuery, useMeQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { useEffect, useState, type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const HardSkillFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { filters, setFilters } = useFilters()
	const { data: dataMe, loading: isMeLoading } = useMeQuery()
	const restrictedFunctionIds = (dataMe?.me?.organization?.matchingRestrictions?.userFunctions ?? []).map(
		({ id }) => id
	)
	const userFunctionId: string =
		restrictedFunctionIds.length === 1
			? restrictedFunctionIds[0]
			: (filters.find((filter) => filter.key === 'userFunctionId')?.value ?? '')

	const [items, setItems] = useState<{ value: string; label: string }[]>([])
	const [currentFunctionId, setCurrentFunctionId] = useState<string>(userFunctionId)

	const [getFunctionData, { loading }] = useGetUserFunctionLazyQuery({
		onCompleted: (res) => {
			const hardSkills = (res?.getUserFunction?.hardSkills ?? []).map((item) => ({
				value: item.id ?? '',
				label: item.label ?? ''
			}))
			setItems(hardSkills)
			setCurrentFunctionId(userFunctionId)
		}
	})

	useEffect(() => {
		if (userFunctionId !== '' && userFunctionId !== 'empty') void getFunctionData({ variables: { userFunctionId } })
		if (currentFunctionId !== userFunctionId) setFilters(filters.filter((filter) => filter.key !== 'topHardSkillIds'))
	}, [userFunctionId, getFunctionData])

	if (userFunctionId === '' || userFunctionId === 'empty') return null

	return (
		<InputFilter keyName={'topHardSkillIds'} operation={filterOperation.CONTAIN_ANY}>
			<AutoCompleteFilter
				options={items}
				label={t('offers:resumeLibrary.filters.hardskills.label')}
				allValueLabel={t('offers:resumeLibrary.filters.hardskills.allLabel')}
				width="100%"
				multiple
				isLoading={loading || isMeLoading}
			/>
		</InputFilter>
	)
}
