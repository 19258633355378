import { useCustomTranslation } from '#translations/useCustomTranslation'
import { DateTime } from 'luxon'

interface UseDate {
	fullDate: (isoDate: string) => string
	shortDate: (isoDate: string) => string
	MedDate: (isoDate: string) => string
}

export const useDate = (): UseDate => {
	const { i18n } = useCustomTranslation()
	const fullDate = (isoDate: string): string => {
		return DateTime.fromISO(isoDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_FULL)
	}
	const shortDate = (isoDate: string): string => {
		return DateTime.fromISO(isoDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_SHORT)
	}
	const MedDate = (isoDate: string): string => {
		return DateTime.fromISO(isoDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED)
	}
	return { fullDate, shortDate, MedDate }
}
