import { type ReactElement, useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { discussionUserService } from '../../../services/Services'
import { DiscussionsFilterBar } from './DiscussionsFilterBar'
import { useDiscussionsColumns } from './useDiscussionsColumns'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { GET_DISCUSSIONUSERS_LIST } from './getDiscussionUserList'
import { useQuery } from '@apollo/client'
import { DataGridGQL } from '../../../features/lists/components/DataGridGQL'
import { useFilters } from '@shared/hooks/useFilters'
import PeopleIcon from '@mui/icons-material/People'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { ScreenId } from '@gqlTypes'

export const Discussions = (): ReactElement => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('translation:discussionListPage.title'))
	useCheckRoleSecurity(ScreenId.Discussions)
	const updateDiscussion = discussionUserService.updateDiscussionUser
	const columns = useDiscussionsColumns(updateDiscussion)
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()
	const { filters } = useFilters()

	const variables = { organizationId, limit: 12, filters }
	const { loading, data, refetch, fetchMore } = useQuery(GET_DISCUSSIONUSERS_LIST, {
		variables
	})
	useDefaultFilter({ refetch })

	const { nodes = [], cursor = '', hasNextPage = false, count = 0 } = data?.getDiscussionUserList ?? {}

	return (
		<DataGridGQL
			emptyStateLabel={t('discussions:dataGrid.empty')}
			columns={columns}
			setIsAllRowsSelected={setIsAllRowsSelected}
			isAllRowsSelected={isAllRowsSelected}
			dataList={nodes}
			isLoading={loading}
			fetchMore={fetchMore}
			cursor={cursor}
			hasNextPage={hasNextPage}
			title={t('discussions:title', { count })}
			Icon={PeopleIcon}
			toolbar={<DiscussionsFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} />}
		/>
	)
}
