import { type ReactElement } from 'react'
import CardLayout from '@ui/core/CardLayout'
import Button from '@mui/material/Button'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { type UserInput } from '@gqlTypes'
import { PositionTrackCardEditDialog } from './PositionTrackCardEditDialog'
import { useToggle } from '@shared/hooks/useToggle'

interface Item {
	value: string
	label: string
}

interface Props {
	availablePositions: Item[]
	userId: string
	saveUser: (userInput: UserInput) => Promise<void>
}

export const OrganizationUserDetailsTrackCard = ({ availablePositions, userId }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const [isDialogOpen, toggleDialogOpen] = useToggle(false)

	return (
		<CardLayout title={t('organizationUsers:details.cards.tracks.title')}>
			<TableLayoutRow
				title={t('organizationUsers:details.cards.tracks.label')}
				tooltip={t('organizationUsers:details.cards.tracks.tooltip')}
			>
				<Button style={{ height: 40 }} variant="contained" onClick={toggleDialogOpen}>
					{t('organizationUsers:details.cards.tracks.openForm')}
				</Button>
			</TableLayoutRow>

			<PositionTrackCardEditDialog
				isOpen={isDialogOpen}
				handleClose={toggleDialogOpen}
				availablePositions={availablePositions}
				userId={userId}
			/>
		</CardLayout>
	)
}
