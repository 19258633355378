import { type GetOfferTrackDetailsQuery, type GetPositionTrackDetailsQuery, type OfferStatus } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import DefaultInterviewLabelField from '../fields/DefaultInterviewLabelField'
import InterviewAverageMandatoryField from '../fields/InterviewAverageMandatoryField'
import InterviewSendingFeedbackField from '../fields/InterviewSendingFeedbackField'
import IsInterviewNotifiedByMailField from '../fields/IsInterviewNotifiedByMailField'
import { StatusField } from '../fields/StatusField'
import { type Control, type FieldErrors } from 'react-hook-form'
import { TrackCheckBox } from '../fields/TrackCheckBox'

interface Props {
	control: Control
	errors: FieldErrors
	details?: GetOfferTrackDetailsQuery['getTrackDetails'] | GetPositionTrackDetailsQuery['getTrackDetails']
	offerStatus: OfferStatus[]
	isOffer: boolean
}

export default function TrackInterviewCard({ control, details, offerStatus, isOffer }: Props): JSX.Element {
	const { t } = useCustomTranslation()

	return (
		<CardLayout title={t('tracks:details.cards.interview')}>
			<TableLayout>
				<DefaultInterviewLabelField control={control} defaultValue={details?.defaultInterviewLabel ?? undefined} />
				<InterviewSendingFeedbackField
					control={control}
					defaultValue={details?.isInterviewSendingFeedback ?? undefined}
				/>
				<InterviewAverageMandatoryField
					control={control}
					defaultValue={details?.isInterviewAverageMandatoryOnly ?? undefined}
				/>
				<IsInterviewNotifiedByMailField
					control={control}
					defaultValue={details?.isInterviewNotifiedByMail ?? undefined}
				/>
				<TrackCheckBox
					control={control}
					fieldName="interviewConfig.isCompletedByAssignableFeedback"
					defaultValue={details?.interviewConfig.isCompletedByAssignableFeedback ?? false}
					details={details}
				/>
				{isOffer ? (
					<>
						<StatusField
							control={control}
							name="statusInterviewPlaned"
							defaultValue={details?.statusInterviewPlaned}
							items={offerStatus}
						/>
						<StatusField
							control={control}
							name="statusInterviewDone"
							defaultValue={details?.statusInterviewDone}
							items={offerStatus}
						/>
					</>
				) : null}
			</TableLayout>
		</CardLayout>
	)
}
