import { type ReactElement, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { relativeToNow } from '#helpers/date.helper'

interface Props {
	updateDate: string
}

export const LastUpdate = ({ updateDate }: Props): ReactElement => {
	const [lastUpdate, setLastUpdate] = useState(relativeToNow(updateDate))
	const { t } = useCustomTranslation()

	useEffect(() => {
		setLastUpdate(relativeToNow(updateDate))
		const id = setInterval(() => {
			setLastUpdate(() => relativeToNow(updateDate))
		}, 10000)
		return () => {
			clearTimeout(id)
		}
	}, [updateDate])

	return (
		<Typography>
			{t('interview:form.saved')} {lastUpdate}
		</Typography>
	)
}
