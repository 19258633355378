import { useCustomTranslation } from '#translations/useCustomTranslation'
import { SelectorUpdater } from '../SelectorUpdater'
import { useMeQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	onValidate: (field: string, newValue: string | boolean) => Promise<void>
}

export const StatusUpdate = ({ onValidate }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useMeQuery()
	if (isLoading || data?.me == null) return null
	const statuses = data.me.organization.offerStatusObject.map(({ id, label }) => ({ value: id, label }))

	return (
		<SelectorUpdater onValidate={onValidate} items={statuses} field="status" title={t('modal:massUpdate.status')} />
	)
}
