import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import { ModalCloseButton } from '@shared/components/modal/ModalCloseButton'
import { type ReactNode, type ReactElement } from 'react'

interface Props {
	title?: string
	cancelLabel?: string
	confirmationLabel?: string
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	isDeleting?: boolean
	onConfirmation?: () => Promise<void>
	children?: ReactNode
}

export const ConfirmationDialog = ({
	title = '',
	cancelLabel = '',
	confirmationLabel = '',
	isOpen,
	setIsOpen,
	isDeleting = false,
	onConfirmation = async () => {},
	children
}: Props): ReactElement => {
	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
		>
			<ModalCloseButton
				onClick={() => {
					setIsOpen(false)
				}}
			/>
			<DialogTitle sx={{ paddingRight: 8 }}>{title}</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					{children}
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								setIsOpen(false)
							}}
							disabled={isDeleting}
						>
							{cancelLabel}
						</Button>
						<LoadingButton
							color="error"
							variant="contained"
							onClick={async () => {
								await onConfirmation()
								setIsOpen(false)
							}}
							disabled={isDeleting}
							loading={isDeleting}
						>
							{confirmationLabel}
						</LoadingButton>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}
