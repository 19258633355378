import { useCustomTranslation } from '#translations/useCustomTranslation'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import CheckBoxField from '@ui/form/CheckBoxField'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: boolean
}

export default function MemberSoftskillReturnField({ control, defaultValue }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.sharedMemberSoftSkillsReturn.title')}
			tooltip={t('tracks:details.formFields.sharedMemberSoftSkillsReturn.tooltip')}
		>
			<CheckBoxField
				control={control}
				name="sharingSettings.sharedMemberSoftSkillsReturn"
				defaultValue={defaultValue}
			/>
		</TableLayoutRow>
	)
}
