import { Autocomplete, TextField } from '@mui/material'
import { type Control, Controller } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { type ReactHookFormRules } from '@ui/form/ReactHookFormRules'
import { type ReactElement } from 'react'

interface Item {
	value: string
	label: string
}

interface Props {
	control?: Control
	rules?: ReactHookFormRules
	name: string
	defaultValue?: Item[]
	items: Item[]
	label?: string
	loading?: boolean
	selectableLimit?: number
	isHiddenOnEmpty?: boolean
}

export default function MultipleAutocompleteField({
	control,
	rules,
	name,
	defaultValue,
	items,
	label = '',
	loading = false,
	selectableLimit = 10,
	isHiddenOnEmpty = false
}: Props): ReactElement {
	const { t } = useCustomTranslation()
	const title = t(`organizationUsers:details.formFields.${name}.title`)
	const tooltip = t(`organizationUsers:details.formFields.${name}.tooltip`)
	const orderedItems = [...items].sort((a, b) => a.label.localeCompare(b.label))

	if (items.length === 0 && isHiddenOnEmpty) {
		return <Controller control={control} name={name} defaultValue={[]} render={() => <></>} />
	}

	return (
		<TableLayoutRow title={title} tooltip={tooltip}>
			<Controller
				control={control}
				rules={rules}
				name={name}
				defaultValue={defaultValue}
				render={({ onChange, value }) => {
					return (
						<Autocomplete
							sx={{ width: '100%' }}
							multiple
							options={orderedItems}
							getOptionLabel={(option) => option.label}
							onChange={(_, newValue) => {
								onChange(newValue)
							}}
							value={value}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" label={label} size="small" key={value.value} />
							)}
							loading={loading}
							getOptionDisabled={(option) => {
								const isAboveTreshold = value.length >= selectableLimit
								const isSelected = value.find((v: Item) => v.value === option.value) == null
								return isAboveTreshold && !isSelected
							}}
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.value}>
										{option.label}
									</li>
								)
							}}
						/>
					)
				}}
			/>
		</TableLayoutRow>
	)
}
