import { useMeQuery } from '@gqlTypes'
import { Typography } from '@mui/material'
import { ChipSelector } from '@ui/form/ChipSelector'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const FeedbackRequestTemplateSelector = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useMeQuery()
	const items = data?.me?.organization.feedbackRequestTemplates ?? []

	return (
		<>
			<Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
				{t('modal:massFeedback.templateId.label')}
			</Typography>
			<ChipSelector name="feedbackRequestTemplateId" items={items} minSelectableItem={1} maxSelectableItem={1} />
		</>
	)
}
