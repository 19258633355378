import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InterviewTargetDate } from '@domains/interviews/list/InterviewTargetDate'
import { type GetPublicResumeQuery } from '@gqlTypes'
import { RouterLink } from '@ui/buttons/RouterLink'
import { type ReactElement } from 'react'
import { generateRoute, ROUTE_INTERVIEW_DETAIL } from '../../../../../router/routes'

interface Props {
	interview: GetPublicResumeQuery['getUserOfferJobMatching'][0]['jobMatching']['candidateInterview']
}

export const JobmatchingInterviewLink = ({ interview }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	if (interview == null) return null
	return (
		<RouterLink
			variant="contained"
			color="primary"
			fullWidth={false}
			route={generateRoute(ROUTE_INTERVIEW_DETAIL, { interviewId: interview?.id ?? '' })}
		>
			<InterviewTargetDate
				{...{
					interviewDate: interview?.interviewDate ?? '',
					isScheduled: interview?.isScheduled ?? false,
					isMissedInterview: interview?.missedInterview ?? false,
					targetDate: interview?.targetDate ?? '',
					interviewStepState: interview?.stepsState.interview ?? false,
					hasStartTime: interview?.hasStartTime ?? false,
					typographySx: { color: 'white' },
					hasIcon: false,
					label: t('offers:jobMatching.browser.interview.targetDate')
				}}
			/>
		</RouterLink>
	)
}
