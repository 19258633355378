import { type ReactElement } from 'react'
import { List, Typography } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { PasswordRule } from './PasswordRule'
import { usePasswordValidation } from '../hooks/usePasswordValidation'

interface Props {
	password: string
}

export const PasswordRules = ({ password }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { hasSpecialChar, hasLowercase, hasUppercase, hasNumber, hastheMinLenght } = usePasswordValidation(password)
	return (
		<>
			<Typography component="p">
				<b>{t('auth:passwordCreation')}</b>
			</Typography>
			<Typography component="p">{t('auth:passwordRuleText')}</Typography>
			<List dense>
				<PasswordRule isValid={hasSpecialChar} textKey="auth:passwordRule.specialChar" />
				<PasswordRule isValid={hasLowercase} textKey="auth:passwordRule.lowercase" />
				<PasswordRule isValid={hasUppercase} textKey="auth:passwordRule.uppercase" />
				<PasswordRule isValid={hasNumber} textKey="auth:passwordRule.number" />
				<PasswordRule isValid={hastheMinLenght} textKey="auth:passwordRule.minLenght" />
			</List>
		</>
	)
}
