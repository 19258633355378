import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetOfferListQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

export const TrackOfferField = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetOfferListQuery({
		variables: {
			cursor: '',
			limit: 0,
			filters: []
		}
	})
	const items = (data?.getOfferList.nodes ?? []).map((offer) => ({ value: offer.id, label: offer.label }))
	const rules = { required: t('drawer:interview.required') }

	return <AutocompleteSelect rules={rules} name="trackOfferId" items={items} label={t('drawer:interview.trackOffer')} />
}
