import { DefaultLayout, type DefaultLayoutProps } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { NavigationTabs } from './NavigationTabs'
import { type TabContent } from './TabContent'
import { TabsRouter } from './TabsRouter'

interface Props extends Omit<DefaultLayoutProps, 'children'> {
	tabs: TabContent[]
}

export const DefaultTabsLayout = ({ tabs, ...layoutProps }: Props): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<DefaultLayout {...layoutProps} title={t('menu.my-profile')} middle={<NavigationTabs tabs={tabs} />}>
			<TabsRouter routes={tabs} />
		</DefaultLayout>
	)
}
