import { TrackOfferFilter } from './TrackOfferFilter'
import { AssessorsFilter } from './AssessorsFilter'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'
import { FilterOperation } from '@ui/filter/FilterForm'

export const OfferAndJury = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<>
			<TrackOfferFilter keyName="orgaOffer.id" labelAll={t('lists:filter.offer.allLabel')} />
			<AssessorsFilter keyName="assessorIds" operation={FilterOperation.IN_ARRAY} isMultiple defaultValue={[]} />
		</>
	)
}
