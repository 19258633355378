import { type ReactElement } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { type OfferInput, useUpdateOfferMutation } from '@gqlTypes'
import { OfferForm } from './OfferForm'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { OffersPath } from '../OffersRouter'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const OfferUpdator = (): ReactElement => {
	const { offerId } = useParams<{ offerId: string }>()
	const { t } = useCustomTranslation()
	const history = useHistory()
	const { onError, onCompleted } = useGqlSnackBar('offers:details.snackbar.update')
	const [updateOfferMutation, { loading: isUpdating }] = useUpdateOfferMutation({ onError, onCompleted })

	const updateOffer = async (offerInput: OfferInput, keepValues: () => Promise<void>): Promise<void> => {
		await keepValues()
		const result = await updateOfferMutation({ variables: { offerId, offerInput } })
		if (result.errors == null) history.push(OffersPath.list)
	}

	return (
		<OfferForm
			isSaving={isUpdating}
			onSave={updateOffer}
			saveLabel={t('offers:details.topBar.button.update')}
			onSaveError={onError}
		/>
	)
}
