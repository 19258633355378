import { GetInterviewObjectivesDocument, useDeleteInterviewObjectiveMutation } from '@gqlTypes'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	isOpen: boolean
	toggleIsOpen: () => void
	objectiveId: string
}

export const InterviewObjectiveDeletionModal = ({ toggleIsOpen, isOpen, objectiveId }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const [deleteObjective, { loading }] = useDeleteInterviewObjectiveMutation({
		variables: { interviewObjectiveId: objectiveId },
		refetchQueries: [GetInterviewObjectivesDocument]
	})

	const onClick = async (): Promise<void> => {
		await deleteObjective()
		toggleIsOpen()
	}

	return (
		<>
			<Button variant="text" color="error" sx={{ maxWidth: 150, textDecoration: 'underline' }} onClick={toggleIsOpen}>
				{t('interview:objectives.modal.edition.delete')}
			</Button>
			<Dialog open={isOpen} fullWidth onClose={toggleIsOpen} PaperProps={{ sx: { borderRadius: 3 } }}>
				<DialogTitle>{t('interview:objectives.modal.deletion.title')}</DialogTitle>
				<DialogCloseButton onClick={toggleIsOpen} />
				<DialogContent>
					<Stack direction={'row'} sx={{ justifyContent: 'space-around' }}>
						<Button variant="contained" onClick={toggleIsOpen}>
							{t('interview:objectives.modal.deletion.cancel')}
						</Button>
						<LoadingButton variant="contained" onClick={onClick} loading={loading} color="error">
							{t('interview:objectives.modal.deletion.delete')}
						</LoadingButton>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}
