import { useState } from 'react'
import { useSnackbar } from 'notistack'
import {
	type InterviewAnswerInput,
	type InterviewSoftSkillInput,
	type UpdateInterviewInput,
	useUpdateInterviewMutation
} from '@gqlTypes'
import { Severities } from '@application/snackbar/Severities'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ScoreCalculation } from './ScoreCalculation'
import { type InterviewFormInput } from './interviewsForm.type'

interface Props {
	interviewId: string
	isInterviewSendingFeedback: boolean
	getValues: () => InterviewFormInput
	trigger: (name?: string | string[] | undefined) => Promise<boolean>
}

interface UseUpdateInterview {
	sendData: (data: InterviewFormInput) => void
	updateDetails: (data: UpdateInterviewPayload) => Promise<void>
	handleSave: (beforeSaving?: () => void, afterSaving?: () => void) => void
	saveDraft: (isDisplayFeedback?: boolean) => void
	isSaving: boolean
	isSavingDraft: boolean
}

interface UpdateInterviewPayload {
	field: string
	value: string | number | boolean | Date
}

export const useUpdateInterview = ({ interviewId, getValues, trigger }: Props): UseUpdateInterview => {
	const [updateInterview] = useUpdateInterviewMutation()
	const { enqueueSnackbar } = useSnackbar()
	const [isSaving, setIsSaving] = useState(false)
	const [isSavingDraft, setIsSavingDraft] = useState(false)
	const { t } = useCustomTranslation()

	const buildSoftSkills = (
		skillComments: Record<string, string>,
		skillScores: Record<string, number>,
		subSkillScoresBySkill: Record<string, number[]>
	): InterviewSoftSkillInput[] => {
		const skillIds = Object.keys(skillScores ?? {})

		return skillIds.map((id) => {
			const subSkillScores: Record<string, number> = {}
			const indexedSubSkillScores = subSkillScoresBySkill ?? {}
			indexedSubSkillScores[id]?.map((v, index) => (subSkillScores[index] = v))
			return {
				id,
				score: skillScores[id] ?? 0,
				comment: skillComments[id] ?? '',
				subSkillScores
			}
		})
	}

	const sendData = async (data: InterviewFormInput, isDisplayFeedback: boolean = true): Promise<void> => {
		const {
			comment,
			answers,
			isDraft,
			globalManualScore,
			globalCalculatedScore,
			skillComments,
			skillScores,
			scoreCalculation,
			subSkillScores
		} = data
		const validatedScore = scoreCalculation === ScoreCalculation.Manual ? globalManualScore / 4 : 0
		const calculatedScore = scoreCalculation === ScoreCalculation.Average ? globalCalculatedScore / 4 : 0

		const softSkills = buildSoftSkills(skillComments, skillScores, subSkillScores)
		const answersArray: InterviewAnswerInput[] = Object.entries(answers ?? {}).map(([id, answer]) => ({
			id,
			answer: answer ?? ''
		}))

		const interviewInput: UpdateInterviewInput = {
			comment,
			calculatedScore,
			validatedScore,
			softSkills,
			answers: answersArray,
			isDraft
		}
		const variables = { interviewId, interviewInput }
		try {
			await updateInterview({ variables })
			if (isDisplayFeedback) {
				enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.success'), { variant: Severities.success })
			}
		} catch (error) {
			enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.error'), { variant: Severities.error })
		}
		setIsSaving(false)
		setIsSavingDraft(false)
	}

	const save = async (data: InterviewFormInput): Promise<void> => {
		setIsSaving(true)
		await sendData({ ...data, isDraft: false })
	}

	const updateDetails = async ({ field, value }: UpdateInterviewPayload): Promise<void> => {
		if (field === 'targetDate' && typeof value !== 'boolean') value = new Date(value)
		const variables = { interviewId, interviewInput: { [field]: value } }
		await updateInterview({ variables })
	}

	const handleSave = async (beforeSaving?: () => void, afterSaving?: () => void): Promise<void> => {
		const isValid = await trigger()
		if (!isValid) {
			setIsSaving(false)
			enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.validationError'), {
				variant: Severities.error
			})
			return
		}
		if (beforeSaving != null) beforeSaving()
		await save(getValues())
		if (afterSaving != null) afterSaving()
	}

	const saveDraft = async (isDisplayFeedback: boolean = true): Promise<void> => {
		setIsSavingDraft(true)
		const data = { ...getValues(), isDraft: true }
		await sendData(data, isDisplayFeedback)
	}

	return {
		handleSave,
		sendData,
		updateDetails,
		saveDraft,
		isSaving,
		isSavingDraft
	}
}
