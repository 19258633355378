import { Stack, Typography, Box } from '@mui/material'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const ProgressionLegend = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<Stack alignItems="center" direction="row" spacing={5} sx={{ height: '100%' }}>
			<Stack direction="row" width={250} alignItems="center" spacing={1}>
				<Box sx={{ borderTop: '2px dashed black', width: 30 }} />
				<Typography>{t('interview:progression.radar.legend.autoEvaluation')}</Typography>
			</Stack>
			<Stack direction="row" width={250} alignItems="center" spacing={1}>
				<Box sx={{ backgroundColor: 'black', height: 2, width: 30 }} />
				<Typography>{t('interview:progression.radar.legend.evaluation')}</Typography>
			</Stack>
		</Stack>
	)
}
