import { useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { BooleanInput } from '@ui/core/BooleanInput'
import { RoutingButtonIcon } from '@ui/buttons/RoutingButtonIcon'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import { generateRoute, ROUTE_TRACKS_OFFER_DETAIL, ROUTE_TRACKS_POSITION_DETAIL } from '../../../router/routes'
import { reduceString } from '../../../helper/string.helper'
import moment from 'moment'
import { TrackType } from '@gqlTypes'
import { TrackExport } from '../details/TrackExport'

interface OrganizationIntegration {
	id: string
	label: string
	isArchived: boolean
	locations: string[]
	description: string
	creationDate?: number
}

export default function useTracksColumns(isTrackOffer: boolean): () => {
	label: string
	width: number
	align?: string
	renderCell: (organizationIntegration: OrganizationIntegration) => JSX.Element
}[] {
	const { t } = useCustomTranslation()
	const detailsRoute = isTrackOffer ? ROUTE_TRACKS_OFFER_DETAIL : ROUTE_TRACKS_POSITION_DETAIL

	return useCallback(() => {
		return [
			{
				label: '',
				width: 78,
				align: 'alignLeft',
				renderCell: (organizationIntegration: OrganizationIntegration) => (
					<RoutingButtonIcon
						tooltipTitle={t('tracks:list.columns.title')}
						route={generateRoute(detailsRoute, {
							organizationIntegrationId: organizationIntegration.id
						})}
						icon={<RecordVoiceOverIcon />}
					/>
				)
			},
			{
				label: t('tracks:list.columns.title'),
				width: 200,
				align: 'alignLeft',
				renderCell: (organizationIntegration: OrganizationIntegration) => (
					<Typography>{organizationIntegration?.label}</Typography>
				)
			},
			{
				label: t('tracks:list.columns.description'),
				width: 200,
				align: 'alignLeft',
				renderCell: (organizationIntegration: OrganizationIntegration) => (
					<Typography>{reduceString(organizationIntegration?.description, 70, '...')}</Typography>
				)
			},
			{
				label: t('tracks:list.columns.isArchived'),
				width: 78,
				align: 'alignLeft',
				renderCell: (organizationIntegration: OrganizationIntegration) => (
					<BooleanInput value={!organizationIntegration.isArchived} noLabel />
				)
			},
			{
				label: t('tracks:list.columns.location'),
				width: 200,
				align: 'alignLeft',
				renderCell: (organizationIntegration: OrganizationIntegration) => {
					const locations = organizationIntegration.locations
					const str = locations != null ? locations.join(', ') : ''
					return <Typography>{str}</Typography>
				}
			},
			{
				label: t('tracks:list.columns.creationDate'),
				width: 200,
				renderCell: (organizationIntegration: OrganizationIntegration) => {
					const timestamp = organizationIntegration?.creationDate
					const value = timestamp != null ? moment(timestamp).format('DD/MM/YYYY') : ''
					return <Typography style={{ cursor: 'pointer' }}>{value}</Typography>
				}
			},
			{
				label: t('tracks:list.columns.export'),
				width: 200,
				renderCell: (track: OrganizationIntegration) => {
					return <TrackExport trackId={track.id} trackType={isTrackOffer ? TrackType.Offer : TrackType.Position} />
				}
			}
		]
	}, [t])
}
