import { Controller } from 'react-hook-form'
import { TextField } from '@ui/core/TextField'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'

export const LabelField = (): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<Controller
			name="label"
			render={({ onChange, value }) => {
				return (
					<TextField
						label={t('drawer:interview.label')}
						variant="outlined"
						size="small"
						onChange={onChange}
						value={value}
					/>
				)
			}}
		/>
	)
}
