import { useGetDepartmentsQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'

export const OfferLocationFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useGetDepartmentsQuery()

	if (isLoading || data?.getDepartments == null) return null
	const userFunctions = data.getDepartments.map((department) => ({
		value: department.id,
		label: department.name
	}))

	return (
		<InputFilter keyName="location.departmentCode" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter options={userFunctions} label={t('lists:filter.location.label')} />
		</InputFilter>
	)
}
