import { type ReactElement, useState } from 'react'
import { type GetUserInterviewListNodesFragment, useMeQuery } from '@gqlTypes'
import { IconButton, Checkbox, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { InterviewCardEditDialog } from './InterviewCardEditDialog'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	interview: GetUserInterviewListNodesFragment
	isSelected?: boolean
	selectRow?: () => void
	isHovered: boolean
}

export const InterviewCardActions = ({ interview, isSelected, selectRow, isHovered }: Props): ReactElement | null => {
	const { data: meData } = useMeQuery()
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useCustomTranslation()
	const isInterviewLabelAvailable = meData?.me?.organization.isInterviewLabelAvailable ?? false
	const isSelectable = selectRow != null && isSelected != null
	const iconColor = isHovered ? 'grays.gray2' : 'transparent'

	if (!isSelectable) return null
	return (
		<>
			<Tooltip title={t('interviews:update.tooltip')}>
				<IconButton
					onClick={() => {
						setIsOpen(true)
					}}
				>
					<EditIcon sx={{ color: iconColor }} />
				</IconButton>
			</Tooltip>
			{isOpen ? (
				<InterviewCardEditDialog
					interview={interview}
					handleClose={() => {
						setIsOpen(false)
					}}
					isOpen={isOpen}
					isInterviewLabelAvailable={isInterviewLabelAvailable}
				/>
			) : null}
			<Tooltip title={t('interviews:select.tooltip')}>
				<Checkbox sx={{ color: iconColor }} checked={isSelected} onChange={selectRow} />
			</Tooltip>
		</>
	)
}
