import { InputFilter } from '@ui/filter/InputFilter'
import { PhoneInput } from '@ui/phone/PhoneInput'
import { FilterOperation } from '@ui/filter/FilterForm'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'

interface Props {
	keyName?: string
	operation?: FilterOperation
	label?: string
}

export const PhoneSelect = ({
	keyName = 'userInfo.mobile',
	operation = FilterOperation.EQUAL,
	label
}: Props): ReactElement => {
	const { t } = useCustomTranslation()

	return (
		<InputFilter keyName={keyName} operation={operation} isTextField>
			<PhoneInput label={label ?? t('lists:filter.phone')} variant="standard" />
		</InputFilter>
	)
}
