import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { ValidateButton } from '../buttons/ValidateButton'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { type ReactElement, type Dispatch, type SetStateAction } from 'react'
interface Props {
	onValidate: (field: string, newValue: string | boolean) => void
	value: string
	setvalue: Dispatch<SetStateAction<string>>
	title: string
	field: string
}

export const BooleanUpdater = ({ onValidate, value, setvalue, title, field }: Props): ReactElement => {
	const { t } = useCustomTranslation()

	const handleSelect = (event: SelectChangeEvent<string>): void => {
		setvalue(event.target.value)
	}

	return (
		<DialogSection style={{ display: 'flex', flexDirection: 'row' }}>
			<label htmlFor="massUpdate-english">{title}</label>
			<Select id="massUpdate-english" value={value} onChange={handleSelect} className="main-dialog-element">
				<MenuItem value>{t('modal:massUpdate.englishEnabled')}</MenuItem>
				<MenuItem value={false}>{t('modal:massUpdate.englishDisabled')}</MenuItem>
			</Select>
			<ValidateButton field={field} onValidate={onValidate} value={value} />
		</DialogSection>
	)
}
