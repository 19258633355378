import { getRowIds } from '../../../../helper/massActions.helper'
import IconButton from '@mui/material/IconButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import Tooltip from '@mui/material/Tooltip'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useSelector } from 'react-redux'
import { type DocumentNode, useLazyQuery } from '@apollo/client'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useFilters } from '@shared/hooks/useFilters'
import { Box } from '@mui/material'
import { useOrganization } from '@domains/organization/useOrganization'
import { type ReactElement } from 'react'

interface Props {
	isNotList?: boolean
	isAllRowsSelected?: boolean
	dataList?: unknown[]
	QUERY: DocumentNode
	itemCount?: number
}

interface RootState {
	selectedRows: unknown[]
}

interface ExportCSVResponse {
	exportCSV: string
}

export const ExportGQL = ({
	isNotList = false,
	isAllRowsSelected = false,
	dataList = [],
	QUERY,
	itemCount = 0
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const selectedRows = useSelector((state: RootState) => state.selectedRows)
	const { filters: filtersState } = useFilters()
	const { organizationId } = useOrganization()

	const onCompleted = (data: ExportCSVResponse): void => {
		const a = document.createElement('a')
		a.href = data.exportCSV
		a.target = '_blank'
		a.click()
	}

	const [exportGQL, { loading: isLoading }] = useLazyQuery<ExportCSVResponse>(QUERY, { onCompleted })

	const exportCSV = async (): Promise<void> => {
		let ids: string[] = []
		if (!isAllRowsSelected) ids = getRowIds(selectedRows, dataList)

		const filters = [...filtersState].filter((filter) => filter.value !== 'empty')
		const variables = isNotList ? { filters } : { filters, ids, organizationId }
		await exportGQL({ variables })
	}

	const disabled = (!isNotList && selectedRows.length < 1) || (isNotList && itemCount < 1) || isLoading

	return (
		<Tooltip title={t('modal:exportCSV.tooltip')} placement="bottom">
			<Box>
				<IconButton onClick={exportCSV} disabled={disabled}>
					{isLoading ? <LocalLoader /> : <SaveAltIcon />}
				</IconButton>
			</Box>
		</Tooltip>
	)
}
