import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, { type AutoCompleteFilterProps } from '../AutoCompleteFilter'
import { type ReactElement } from 'react'

export const CustomFieldsFilters = (props: AutoCompleteFilterProps): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return null

	const interviewFields = data.me.organization.interviewFields

	return (
		<>
			{interviewFields.map((field) => (
				<div key={field?.id}>
					<InputFilter keyName={`customFields.${field?.id ?? ''}`} operation={filterOperation.EQUAL}>
						<AutoCompleteFilter
							{...props}
							options={field?.values?.map((value) => ({ value, label: value ?? '' })) ?? []}
							label={field?.label}
							allValueLabel={t('lists:filter.assessor.allLabel')}
							width="100%"
						/>
					</InputFilter>
				</div>
			))}
		</>
	)
}
