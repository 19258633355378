import { Marker } from './Marker'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { type ReactElement } from 'react'

interface Props {
	value: number
	selfEvaluatedDetailedLabel?: string
}

export const SelfEvaluationMarker = ({ value, selfEvaluatedDetailedLabel = '' }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const title = t('translation:candidates.profile.selfEvaluation')

	const label = selfEvaluatedDetailedLabel !== '' ? `${title} :\n${selfEvaluatedDetailedLabel}` : title

	return <Marker label={label} value={value} icon={<PersonOutlineOutlinedIcon fontSize="inherit" />} />
}
