import { useEffect, type ReactElement } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useForm, FormProvider } from 'react-hook-form'
import { useSubscribeUserToPositionMutation } from '@gqlTypes'
import { DateField } from '@domains/interviews/create/DateField'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'

interface Item {
	value: string
	label: string
}

interface Props {
	availablePositions: Item[]
	userId: string
	isOpen: boolean
	handleClose: () => void
}

interface FormInputs {
	trackId: string
	registerDate: string
}

export const PositionTrackCardEditDialog = ({
	availablePositions,
	userId,
	isOpen,
	handleClose
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const [subscribePosition, { loading: isSubmitting }] = useSubscribeUserToPositionMutation()

	const formMethods = useForm<FormInputs>({
		defaultValues: {
			trackId: '',
			registerDate: new Date(new Date().toISOString().split('T')[0]).toISOString()
		}
	})
	const { handleSubmit, reset } = formMethods

	useEffect(() => {
		if (isOpen) reset()
	}, [isOpen, reset])

	const onSubmit = async ({ trackId, registerDate }: FormInputs): Promise<void> => {
		await subscribePosition({ variables: { userId, trackId, registerDate } })
		handleClose()
	}

	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth>
			<FormProvider {...formMethods}>
				<DialogTitle>{t('organizationUsers:details.cards.tracks.formTitle')}</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} sx={{ mt: 1 }}>
						<Grid item xs={12}>
							<AutocompleteSelect
								name="trackId"
								items={availablePositions}
								label={t('organizationUsers:details.cards.tracks.selectTrack')}
							/>
						</Grid>
						<Grid item xs={12}>
							<DateField name="registerDate" label={t('organizationUsers:details.cards.tracks.registerDate')} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t('organizationUsers:details.cards.tracks.cancel')}</Button>
					<LoadingButton
						aria-hidden="false"
						onClick={handleSubmit(onSubmit)}
						type="submit"
						loading={isSubmitting}
						variant="contained"
					>
						{t('organizationUsers:details.cards.tracks.submit')}
					</LoadingButton>
				</DialogActions>
			</FormProvider>
		</Dialog>
	)
}
