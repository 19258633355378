import { useGetInterviewTemplateQuery, useMeQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import CheckBoxField from '@ui/form/CheckBoxField'
import InputSelectField from '@ui/form/InputSelectField'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useParams } from 'react-router-dom'
import { SkillIdsInput } from './SkillIdsInput'

export const InterviewTemplateGeneralCard = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { loading: isMeLoading, data: meData } = useMeQuery()
	const { control } = useFormContext()
	const { interviewTemplateId } = useParams<{ interviewTemplateId: string }>()
	const { loading: isTemplateLoading, data: templateData } = useGetInterviewTemplateQuery({
		variables: { interviewTemplateId }
	})

	if (isMeLoading || isTemplateLoading) return <LocalLoader />
	if (
		meData?.me == null ||
		templateData?.getInterviewTemplate == null ||
		templateData?.getTrackOfferList == null ||
		templateData?.getTrackPositionList == null
	) {
		return null
	}

	const offers = templateData?.getTrackOfferList?.nodes ?? []
	const positions = templateData?.getTrackPositionList?.nodes ?? []
	const tracks: { label: string; id: string; trackSkills: { id: string; label: string }[] }[] = [
		...offers,
		...positions
	]

	const {
		trackId,
		trackSkills,
		interviewType,
		hasSoftSkills,
		hasHardSkills,
		isDisplayMotivationVideos,
		hasObjectives
	} = templateData.getInterviewTemplate

	return (
		<CardLayout title={t('interviewTemplate:detail.generalInfos.title')}>
			<TableLayout>
				<TableLayoutRow
					title={t('interviewTemplate:detail.formFields.interviewType.label')}
					tooltip={t('interviewTemplate:detail.formFields.interviewType.tooltip')}
				>
					<InputSelectField
						name="interviewType"
						items={meData.me.organization.interviewTypes}
						control={control}
						defaultValue={interviewType}
						displayKey="label"
						errors={{}}
						rules={{}}
					/>
				</TableLayoutRow>
				<TableLayoutRow
					title={t('organizationUsers:details.cards.tracks.label')}
					tooltip={t('organizationUsers:details.cards.tracks.tooltip')}
				>
					<InputSelectField
						name="trackId"
						items={tracks}
						control={control}
						defaultValue={trackId}
						displayKey="label"
						errors={{}}
						rules={{}}
					/>
				</TableLayoutRow>
				<SkillIdsInput tracks={tracks} defaultTrackId={trackId} defaultSofskillIds={trackSkills.map(({ id }) => id)} />
				<TableLayoutRow
					title={t('interviewTemplate:detail.formFields.hasSoftSkills.label')}
					tooltip={t('interviewTemplate:detail.formFields.hasSoftSkills.tooltip')}
				>
					<CheckBoxField control={control} name="hasSoftSkills" defaultValue={hasSoftSkills} />
				</TableLayoutRow>
				<TableLayoutRow
					title={t('interviewTemplate:detail.formFields.hasHardSkills.label')}
					tooltip={t('interviewTemplate:detail.formFields.hasHardSkills.tooltip')}
				>
					<CheckBoxField control={control} name="hasHardSkills" defaultValue={hasHardSkills} />
				</TableLayoutRow>
				<TableLayoutRow
					title={t('interviewTemplate:detail.formFields.hasObjectives.label')}
					tooltip={t('interviewTemplate:detail.formFields.hasObjectives.tooltip')}
				>
					<CheckBoxField control={control} name="hasObjectives" defaultValue={hasObjectives} />
				</TableLayoutRow>
				<TableLayoutRow
					title={t('interviewTemplate:detail.formFields.isDisplayMotivationVideos.label')}
					tooltip={t('interviewTemplate:detail.formFields.isDisplayMotivationVideos.tooltip')}
				>
					<CheckBoxField control={control} name="isDisplayMotivationVideos" defaultValue={isDisplayMotivationVideos} />
				</TableLayoutRow>
			</TableLayout>
		</CardLayout>
	)
}
