import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import DatepickerField from '@ui/form/DatepickerField'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: Date
}

export default function PublicationDateField({ control, defaultValue }: Props): JSX.Element {
	const { t } = useCustomTranslation()

	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.publicationDate.title')}
			tooltip={t('tracks:details.formFields.publicationDate.tooltip')}
		>
			<DatepickerField control={control} name="publicationDate" defaultValue={defaultValue} timestamp={false} />
		</TableLayoutRow>
	)
}
