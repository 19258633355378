import { Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	id: string
	mandatory: boolean
	orgaOnly: boolean
	onToggleIsMandatory: (id: string) => void
	onToggleIsOrgaOnly: (id: string) => void
}

export function SoftskillItem({
	id,
	mandatory,
	orgaOnly,
	onToggleIsMandatory,
	onToggleIsOrgaOnly
}: Props): JSX.Element {
	const { t } = useCustomTranslation()

	return (
		<FormGroup row>
			<Tooltip title={t('tracks:details.cards.softskills.columns.mandatory.tooltip')}>
				<FormControlLabel
					control={
						<Checkbox
							checked={mandatory}
							onChange={() => {
								onToggleIsMandatory(id)
							}}
							data-no-dnd
						/>
					}
					label={t('tracks:details.cards.softskills.columns.mandatory.label')}
				/>
			</Tooltip>
			<Tooltip title={t('tracks:details.cards.softskills.columns.orgaOnly.tooltip')}>
				<FormControlLabel
					control={
						<Checkbox
							checked={orgaOnly}
							onChange={() => {
								onToggleIsOrgaOnly(id)
							}}
							data-no-dnd
						/>
					}
					label={t('tracks:details.cards.softskills.columns.orgaOnly.label')}
				/>
			</Tooltip>
		</FormGroup>
	)
}
