import { VideoEmbed } from '@ui/core/VideoEmbed'
import { Box, IconButton, Typography, Paper, Stack, Tooltip } from '@mui/material'
import { File } from './FIle'
import { type File as FileType, useSetHasWebOnboardingOpenMutation } from '@gqlTypes'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ErrorHandler } from '#helpers/error.helper'
import MDIIcon from '@ui/icon/MDIIcon'
import { type ReactElement } from 'react'
import { ParagraphWithBreakLine } from '@ui/typography/ParagraphWithBreakLine'
import { SupportButton } from '@application/support/SupportButton'

interface Props {
	message: string
	video: string
	files: FileType[]
	isOpen: boolean
	firstname: string
}

export const Onboarding = ({ message, video, files, isOpen, firstname }: Props): ReactElement => {
	const hasWebOnboardingOpen = !isOpen
	const variables = { hasWebOnboardingOpen }
	const optimisticResponse = {
		setHasWebOnboardingOpen: {
			id: firebaseAuth()?.currentUser?.uid ?? '',
			__typename: 'User' as const,
			hasWebOnboardingOpen
		}
	}

	const [toogleIsOpenMutation] = useSetHasWebOnboardingOpenMutation({ variables, optimisticResponse })
	const { t } = useCustomTranslation()

	const toogleIsOpen = (): void => {
		;(async () => {
			await toogleIsOpenMutation()
		})().catch(ErrorHandler)
	}
	return (
		<Box sx={{ position: 'absolute', right: 0, top: 0, zIndex: 20 }}>
			<Stack direction="row" sx={{ alignItems: 'start', mt: 0 }}>
				<SupportButton />
				<Tooltip title={t('onboardingHelp:newsTooltip')}>
					<IconButton target="_blank" href="https://emage-me.com/newsletter">
						<MDIIcon color={'primary'} name="light-bulb-outline" />
					</IconButton>
				</Tooltip>
				{isOpen ? null : (
					<Tooltip title={t('onboardingHelp:onboardingTooltip')}>
						<IconButton sx={{ mr: 1 }} onClick={toogleIsOpen}>
							<HelpOutlineIcon color="primary" />
						</IconButton>
					</Tooltip>
				)}
				{isOpen ? (
					<Paper elevation={10} sx={{ width: 400, backgroundColor: '#f9fcff', minHeight: '100%' }}>
						<IconButton sx={{ mr: 1, mt: 1, position: 'absolute', right: 0 }} onClick={toogleIsOpen}>
							<CloseIcon color="primary" />
						</IconButton>
						<Box sx={{ width: 400, backgroundColor: '#f9fcff', minHeight: '100%' }}>
							<Box sx={{ padding: 4 }}>
								<Typography color="primary" variant="h4" sx={{ mr: 3 }}>
									{t('onboardingHelp:welcome', { firstname })}
								</Typography>
								<ParagraphWithBreakLine>{message}</ParagraphWithBreakLine>
								{video !== '' ? (
									<>
										<Typography color="primary" variant="h5" sx={{ mt: 3, mb: 1 }}>
											{t('onboardingHelp:video')}
										</Typography>
										<VideoEmbed src={video} style={{ height: 200 }} />
									</>
								) : null}
								{files.length > 0 ? (
									<>
										<Typography color="primary" variant="h5" sx={{ mt: 3, mb: 2 }}>
											{t('onboardingHelp:files')}
										</Typography>
										{files.map(({ name, link }) => (
											<File key={link} {...{ name, link }} />
										))}
									</>
								) : null}
							</Box>
						</Box>
					</Paper>
				) : null}
			</Stack>
		</Box>
	)
}
